<template>
  <footer-one :list="list" title="Принимаем все виды оплаты" mods="_payment" />
</template>

<script>
import FooterOne from '@/components/AppFooter/FooterOne';
// icons
import visa from '@/assets/sprite/svg/pay-systems/visa.svg';
import mastercard from '@/assets/sprite/svg/pay-systems/mastercard.svg';
import mir from '@/assets/sprite/svg/pay-systems/mir.svg';

export default {
  name: 'PaymentList',
  components: {
    FooterOne,
  },
  data() {
    return {
      list: [
        {
          icon: {
            width: 33,
            height: 26,
            component: visa,
          },
        },
        {
          icon: {
            width: 34,
            height: 26,
            component: mastercard,
          },
        },
        {
          icon: {
            width: 33,
            height: 26,
            component: mir,
          },
        },
      ],
    };
  },
};
</script>
