<template>
  <div v-if="!isMobile" class="app-banner">
    <button
      class="app-banner__close"
      type="button"
      @click="$emit('close')"
    ></button>
    <div class="app-banner__container contentPart">
      <div class="app-banner__content">
        <bike-icon class="app-banner__bike" />
        <div class="app-banner__body">
          <p class="app-banner__title">
            Доставим ваш заказ в {{ locationName }}
          </p>
          <p class="app-banner__text">
            Стоимость доставки от {{ deliveryPrice }} руб
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="app-banner-mobile">
    <div class="app-banner-mobile__content">
      <bike-icon-mobile class="app-banner-mobile__icon _bike" />
      <div class="app-banner-mobile__title">
        Доставляем заказ в {{ locationName }}
      </div>
      <div class="app-banner-mobile__text">
        Стоимость доставки от {{ deliveryPrice }} руб
      </div>
    </div>
    <button
      class="app-banner-mobile__close"
      @click="$emit('close')"
    >
      <close24-icon class="app-banner-mobile__icon" />
    </button>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { checkMobile } from '@/utils/deviceCheck';

import BikeIcon from '@/assets/sprite/svg/bike.svg';
import BikeIconMobile from '@/assets/sprite/svg/bike-mobile.svg';
import Close24Icon from '@/assets/sprite/svg/24/Close_24.svg';

export default defineComponent({
  name: 'Location',
  components: {
    BikeIcon,
    Close24Icon,
    BikeIconMobile,
  },
  props: {
    deliveryPrice: { type: Number, required: true },
    deliveryTime: { type: String, required: true },
    locationName: { type: String, required: true },
  },
  setup() {
    const isMobile = checkMobile();

    return { isMobile };
  },
});
</script>
