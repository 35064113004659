<template>
  <span itemscope itemtype="https://schema.org/Organization">
    <meta itemprop="name" content="Book24.ru" />
    <link itemprop="sameAs" href="https://book24.ru/" />
    <meta itemprop="telephone" :content="phone" />
    <schema-postal-address itemprop="address" />
  </span>
</template>

<script>
import { defineComponent, computed, useStore } from '@nuxtjs/composition-api';
import SchemaPostalAddress from '@/components/microdata/SchemaPostalAddress.vue';

export default defineComponent({
  name: 'SchemaOrganization',
  components: { SchemaPostalAddress },
  setup() {
    const store = useStore();

    const phone = computed(() => store.state.main.currentPhone);

    return { phone };
  },
});
</script>
