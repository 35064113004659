var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-suggestions"},[(_vm.title)?_c('div',{staticClass:"search-suggestions__title"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.isHistory)?_c('button',{staticClass:"search-suggestions__button",on:{"click":_vm.clearHistoryHandler}},[_vm._v(" Очистить ")]):_vm._e()]):_vm._e(),_c('ul',{staticClass:"search-suggestions__list"},_vm._l((_vm.results),function(item,key){return _c('li',{key:item.id,staticClass:"search-suggestions__list-item",class:{
        _hovered: _vm.active === item.id,
        _regular: !_vm.isCategory(item) && !_vm.isProduct(item),
        _category: _vm.isCategory(item),
        _product: _vm.isProduct(item),
      }},[(!_vm.isProduct(item))?_c('app-link',{directives:[{name:"sanitize",rawName:"v-sanitize",value:(_vm.getSearchCaption(item.title, _vm.query)),expression:"getSearchCaption(item.title, query)"}],staticClass:"search-suggestions__link",class:{
          _category: _vm.isCategory(item),
        },attrs:{"href":item.link},nativeOn:{"click":function($event){return _vm.linkClickHandler(item.title, item.link)}}}):_c('search-product',{class:{_hovered: _vm.active === item.id},attrs:{"id":item.meta.id,"url":item.link,"name":_vm.getSearchCaption(item.title, _vm.query),"price-base":item.meta.originalPrice,"price":item.meta.price,"percent-discount":item.meta.discount,"img":item.meta.image},on:{"clickHandler":function($event){return _vm.linkClickHandler(item.title, item.link)}}}),(_vm.isCategory(item))?_c('div',{staticClass:"search-suggestions__bread-crumbs"},_vm._l((item.meta.breadCrumb),function(breadCrumb){return _c('app-link',{directives:[{name:"sanitize",rawName:"v-sanitize",value:(_vm.getSearchCaption(breadCrumb.name, _vm.query)),expression:"getSearchCaption(breadCrumb.name, query)"}],key:breadCrumb.url,staticClass:"search-suggestions__link _category-bread-crumb",attrs:{"href":breadCrumb.url},nativeOn:{"click":function($event){return _vm.linkClickHandler(breadCrumb.name, breadCrumb.url)}}})}),1):_vm._e(),(_vm.isHistory)?_c('button',{staticClass:"search-suggestions__list-clear",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.clearHistoryHandler(key)}}},[_c('svg-icon',{staticClass:"search-suggestions__list-clear-icon",attrs:{"name":"24/Close_24","width":"24","height":"24"}})],1):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }