<template>
  <footer-one title="Присоединяйтесь к нам в соцсетях" :list="list" mods="_socials" />
</template>

<script>
import FooterOne from '@/components/AppFooter/FooterOne';
// icons
import Vk34x34 from '@/assets/sprite/svg/34/Vk_34x34.svg';
import Telegram34x34 from '@/assets/sprite/svg/34/Telegram_34x34.svg';
import YouTube34x34 from '@/assets/sprite/svg/34/YouTube_34x34.svg';
import Pinterest34x34 from '@/assets/sprite/svg/34/Pinterest_34x34.svg';
import YandexZen136x34 from '@/assets/sprite/svg/34/YandexZen_136x34.svg';

export default {
  name: 'SocialsList',
  components: {
    FooterOne,
  },
  data() {
    return {
      list: [
        {
          link: 'https://vk.com/book24_ru',
          icon: {
            width: 34,
            height: 34,
            component: Vk34x34,
          },
        },
        {
          link: 'https://t.me/book24_ru_book24',
          icon: {
            width: 34,
            height: 34,
            component: Telegram34x34,
          },
        },
        {
          link: 'https://www.youtube.com/channel/UCKUkZ6ojB7hBbjMutKEup0g?view_as=subscriber',
          icon: {
            width: 34,
            height: 34,
            component: YouTube34x34,
          },
        },
        {
          link: 'https://www.pinterest.ru/book24ru/pins/',
          icon: {
            width: 34,
            height: 34,
            component: Pinterest34x34,
          },
        },
        {
          link: 'https://zen.yandex.ru/book24',
          icon: {
            width: 136,
            height: 34,
            component: YandexZen136x34,
          },
        },
      ],
    };
  },
};
</script>
