import { computed, provide, useStore } from '@nuxtjs/composition-api';
import useWishList from '@/compositions/wishList/useWishList';

const useWishListLayout = () => {
  const store = useStore();
  const wishList = useWishList();
  provide('wishList', wishList);

  const isAuthorized = computed({
    get: () => store.state.isAuthorized,
    set: () => {},
  });

  const getProductsWishList = async () => {
    if (isAuthorized.value) {
      await wishList.getProductsWishList();
    }
  };

  return { getProductsWishList };
};

export default useWishListLayout;
