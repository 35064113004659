<template>
  <header class="header">
    <div class="header__row _bordered">
      <div class="header__container">
        <app-slogan class="header__slogan" />
        <div class="header__second-menu">
          <second-menu />
        </div>
        <div class="header__right-content">
          <delivery-link class="header__delivery" />
          <choose-city :location-code="serverLocationCode" />
          <fast-contacts :current-phone="currentPhone" class="header__fast-contacts" />
        </div>
      </div>
    </div>
    <div class="header__row _middle">
      <div class="header__container">
        <app-logo class="header__logo" />
        <!--noindex-->
        <the-search class="header__search" />
        <!--/noindex-->
        <user-menu class="header__user-menu" />
        <header-basket class="header__cart" />
      </div>
    </div>
    <div class="header__row _shadow">
      <div class="header__container">
        <main-menu class="header__main-menu" />
      </div>
    </div>
  </header>
</template>

<script>
import { useStore, computed } from '@nuxtjs/composition-api';

import AppLogo from '@/components/_desktop/AppHeader/AppLogo';
import TheSearch from '@/components/_desktop/AppHeader/TheSearch';
import HeaderBasket from '@/components/_desktop/HeaderBasket';
import AppSlogan from '@/components/_desktop/AppHeader/AppSlogan/AppSlogan';
import DeliveryLink from '@/components/_desktop/AppHeader/DeliveryLink';
import SecondMenu from '@/components/SecondMenu';
import ChooseCity from '@/components/ChooseCity';
import FastContacts from '@/components/_desktop/AppHeader/FastContacts';
import MainMenu from '@/components/_desktop/AppHeader/MainMenu';
import UserMenu from '@/components/_desktop/AppHeader/UserMenu';

export default {
  name: 'AppHeader',
  comments: true,
  components: {
    AppLogo,
    TheSearch,
    HeaderBasket,
    AppSlogan,
    SecondMenu,
    DeliveryLink,
    FastContacts,
    ChooseCity,
    MainMenu,
    UserMenu,
  },
  setup() {
    const { state } = useStore();

    const currentPhone = computed(() => state.main.currentPhone);
    const serverLocationCode = computed(() => state.serverLocationCode);
    const isAuthorized = computed(() => state.isAuthorized);

    return {
      isAuthorized,
      serverLocationCode,
      currentPhone,
    };
  },
};
</script>

<style lang="less">
.header {
  display: block;
  z-index: 3;
  position: relative;

  &__container {
    .container-d;

    display: flex;
    align-items: center;
  }

  &__row {
    &._middle {
      padding: @size-x5 0;
      margin-bottom: -@size-x3;
    }

    &._bordered {
      border-bottom: 1px solid @gray-super-light;
    }

    &._shadow {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 11px;
        opacity: 0.06;
        background-image: linear-gradient(-180deg, @black-native, hsla(0, 0%, 100%, 0) 62%);
      }
    }
  }

  &__logo {
    margin-right: @size-x6;
  }

  &__search {
    margin-right: 30px;
  }

  &__right-content {
    display: flex;
    align-items: stretch;
    margin-left: auto;
    border-left: 1px solid @gray-super-light;

    & > *:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__fast-contacts {
    border-left: 1px solid @gray-super-light;
  }

  &__delivery {
    display: flex;
    align-items: center;
    padding-left: @size-x5;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__second-menu {
    display: grid;
    flex-grow: 1;
  }

  &__slogan + &__second-menu {
    margin: 0 @size-x5;
  }

  &__main-menu {
    margin: 0 -@size-x5;
    flex: auto;
  }

  &__user-menu {
    margin-right: auto;
  }

  &__user-menu + &__cart {
    margin-left: @size-x2;
  }
}
</style>
