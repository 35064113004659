import { reactive, readonly } from '@nuxtjs/composition-api';

import {
  ERRORS_GET_WISH_LIST,
  ERRORS_ADD_TO_WISH_LIST,
  ERRORS_REMOVE_IN_WISH_LIST,
} from '@/utils/constants/wishListConstants';

import { useAnalyticProducts } from '@/compositions/analytics/useAnalyticProducts';
import useHttpClient from '@/compositions/useHttpClient';

const useWishList = () => {
  const { wishList } = useHttpClient();
  const analyticProducts = useAnalyticProducts();

  const state = reactive({
    productsId: [],
    isEmpty: false,
  });

  /**
   * Устанавливает список идентификаторов отложенных товаров
   * @param list
   */
  const setProductsId = (list = []) => {
    state.productsId = list;
  };

  /**
   * Добавляет id това к списку идентификаторов отложенных товаров
   * @param {number} productId
   */
  const addProductsId = (productId) => {
    const id = state.productsId.find(item => item === productId);
    if (!id) {
      state.productsId.push(productId);
    }
  };

  /**
   * Удаляет id товара из списка идентификаторов отложенных товаров
   * @param productId
   */
  const removeProductsId = (productId) => {
    const idIndex = state.productsId.findIndex(item => item === productId);
    if (idIndex !== -1) {
      state.productsId.splice(idIndex, 1);
    }
  };

  /**
   * Получает список идентификаторов товаров
   * @param {Object} options
   * @return {Promise<unknown>}
   */
  const getProductsWishList = async (options = {}) => new Promise((resolve) => {
    wishList.getWishList({ perPage: 100, ...options })
      .then(({ list, pagination }) => {
        const items = list.map(item => Number(item.id));
        setProductsId(items);
        state.isEmpty = !items.length;
        resolve({ list, pagination });
      })
      .catch((error) => {
        if (!ERRORS_GET_WISH_LIST.getItemByCode(error?.code)) throw error;
      });
  });

  /**
   * Добавляет товар в отложенные
   * @param {*}
   * @returns
   */
  const addToWishList = ({
    productId,
    product = {},
    targetProperties = {},
  }) => new Promise((resolve) => {
    wishList.addToWishList(
      productId,
      targetProperties,
    )
      .then(() => {
        addProductsId(productId);
        resolve();
      })
      .catch((error) => {
        if (!ERRORS_ADD_TO_WISH_LIST.getItemByCode(error?.code)) throw error;
      })
      .finally(() => {
        analyticProducts.sendAnalyticAddWishlist([product]);
      });
  });

  /**
   * Удаляет товар из списка отложенных
   * @param {*}
   * @returns
   */
  const removeInWishListItem = ({
    productId,
    product = {},
  }) => new Promise((resolve) => {
    wishList.removeInWishListItem(productId)
      .then(() => {
        removeProductsId(productId);
        resolve();
      })
      .catch((error) => {
        if (!ERRORS_REMOVE_IN_WISH_LIST.getItemByCode(error?.code)) throw error;
      })
      .finally(() => {
        analyticProducts.sendAnalyticRemoveWishlist([product]);
      });
  });

  return {
    state: readonly(state),
    setProductsId,
    addProductsId,
    removeProductsId,
    getProductsWishList,
    addToWishList,
    removeInWishListItem,
  };
};

export default useWishList;
