<template>
  <div data-b24-analytic-wrapper>
    <div :key="nuxtRenderKey" class="page-wrap">
      <client-only>
        <transition name="component-fade" mode="out-in">
          <the-poster-skeleton v-if="isLoadingBanner" />
          <the-poster
            v-else-if="banner"
            :id="banner.id"
            :name="banner.name"
            :url="banner.url"
            :banner-group="banner.type"
            :banner-parameters="banner.parameters"
            :image-original-src="banner.imageOriginalSrc"
          />
        </transition>
        <template #placeholder>
          <the-poster-skeleton />
        </template>
      </client-only>
      <app-header />
      <main id="main" class="page-wrap__main">
        <div
          v-if="activeThemeClass === '_new-year-theme'"
          class="page-wrap__theme-background"
        >
        </div>
        <Nuxt class="page-wrap__inner" />
      </main>
      <lazy-hydrate
        v-if="showFooter"
        :when-visible="{ rootMargin: '100px' }"
      >
        <app-footer />
      </lazy-hydrate>
      <app-newbies-notification
        v-if="!isAuthorized"
        class="page-wrap__notification"
      />
      <client-only>
        <app-cookies-notification
          v-if="showCookies"
          class="page-wrap__notification _cookies"
        />
      </client-only>
      <portals-target />
      <schema-organization />
      <client-only>
        <modals-container />
      </client-only>
    </div>
    <app-flocktory-setup />
  </div>
</template>

<script>
import {
  defineComponent,
  onBeforeMount,
  useRoute,
  watch,
  useRouter,
  useContext,
  onMounted,
  computed,
} from '@nuxtjs/composition-api';

import LazyHydrate from 'vue-lazy-hydration';

import { PROD_DOMAIN } from '@/utils/constants/globalConst';

import useLayoutDefault from '@/compositions/layout/useLayoutDefault';
import useNuxtRenderKey from '@/compositions/layout/useNuxtRenderKey';
import useLayoutBlocks from '@/compositions/layout/useLayoutBlocks';
import useWishListLayout from '@/compositions/layout/useWishListLayout';
import useUserSession from '@/compositions/layout/useUserSession';
import usePrevRouteLink from '@/compositions/layout/usePrevRouteLink';
import useRedirectReturnOfGoodsPage from '@/compositions/layout/useRedirectReturnOfGoodsPage';
import useRecommendationsLayout from '@/compositions/layout/useRecommendationsLayout';
import useClickRecorder from '@/compositions/layout/useClickRecorder';
import usePartner from '@/compositions/user/usePartner';

import { useBasketProvider, useBasketContext } from '@/compositions/basket/basketStorage';
import { useLocationProvider, useLocationContext } from '@/compositions/location/locationStorage';
import { useAuthorizationProvider } from '@/compositions/authorization/authorizationStorage';
import { useAdultProvider } from '@/compositions/adult/adultStorage';
import useLogin from '@/compositions/authorization/useLogin';

import AppHeader from '@/components/_desktop/AppHeader/index';
import AppFooter from '@/components/AppFooter/index';
import PortalsTarget from '@/components/PortalsTarget';
import ThePoster from '@/components/_desktop/ThePoster/index.vue';
import ThePosterSkeleton from '@/components/_desktop/ThePoster/skeleton.vue';
import SchemaOrganization from '@/components/microdata/SchemaOrganization';
import AppCookiesNotification from '@/components/AppCookiesNotification';
import AppNewbiesNotification from '@/components/AppNewbiesNotification';
import AppFlocktorySetup from '@/components/AppFlocktorySetup/index.vue';

export default defineComponent({
  name: 'NuxtDefaultLayout',
  components: {
    AppFlocktorySetup,
    AppNewbiesNotification,
    AppCookiesNotification,
    SchemaOrganization,
    ThePoster,
    PortalsTarget,
    AppHeader,
    AppFooter,
    ThePosterSkeleton,
    LazyHydrate,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  setup() {
    useLayoutDefault();
    usePrevRouteLink();
    useBasketProvider();
    useLocationProvider();
    useAuthorizationProvider();
    useAdultProvider();
    const { fetchBasket, resetData: resetDataBasket } = useBasketContext();
    const { initLocation } = useLocationContext();
    const redirectReturnOfGoodsPage = useRedirectReturnOfGoodsPage();
    const wishListLayout = useWishListLayout();
    const userSession = useUserSession();
    const clickRecorder = useClickRecorder();
    const recommendationsLayout = useRecommendationsLayout();
    const { nuxtRenderKey } = useNuxtRenderKey();
    const layoutBlocks = useLayoutBlocks();
    const { $cookies } = useContext();
    const route = useRoute();
    const router = useRouter();
    const partner = usePartner();
    const { login, redirectFromTinkoffLoginHandler } = useLogin();

    watch(
      () => route.value,
      () => {
        partner.checkCurrentUserPartner();
      },
    );

    watch(
      () => nuxtRenderKey.value,
      async (value, oldValue) => {
        if (value !== oldValue) {
          redirectReturnOfGoodsPage.switchRoutes();

          const promiseList = [];
          if (route.value.name !== 'basket-page') {
            promiseList.push(fetchBasket());
          }

          await Promise.allSettled([
            ...promiseList,
            userSession.updateUserSession(),
          ])
            .then(async () => {
              layoutBlocks.checkCookiesAccepted();
              await Promise.allSettled([
                layoutBlocks.getThroughBanner(),
                recommendationsLayout.updateHasRecommendations(),
                partner.checkCurrentUserPartner(),
              ]);
            });
        }
      },
      { immediate: false },
    );

    const clearBasketId = () => {
      resetDataBasket();
      $cookies.remove('BITRIX_SM_SALE_UID');
      $cookies.remove('BITRIX_SM_SALE_UID', { domain: `.${PROD_DOMAIN}` });
      router.push('/');
    };

    const needClearBasketId = computed(() => route.value.query?.clearBasketId === 'y');

    onBeforeMount(async () => {
      const promiseList = [];

      if (needClearBasketId.value) {
        clearBasketId();
      }

      if (route.value.query?.authIssueId && route.value.query?.authIssueId.length) {
        await login(route.value.query.authIssueId, needClearBasketId.value);
        return;
      }

      if (route.value.name !== 'basket-page') {
        promiseList.push(fetchBasket());
      }

      await Promise.allSettled([
        ...promiseList,
        initLocation(),
        userSession.updateUserSession(),
        clickRecorder.sendClick(),
        wishListLayout.getProductsWishList(),
      ])
        .then(async () => {
          layoutBlocks.checkCookiesAccepted();
          await Promise.allSettled([
            partner.checkCurrentUserPartner(),
            recommendationsLayout.saveRecoInit(),
            layoutBlocks.getThroughBanner(),
          ]);
        });
    });

    onMounted(async () => {
      await redirectFromTinkoffLoginHandler();
    });

    return {
      ...layoutBlocks,
      nuxtRenderKey,
      activeThemeClass: null,
    };
  },
  head: {},
});
</script>

<style lang="less">
.page-wrap {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &__main {
    position: relative;
    flex: 1;
  }

  &__inner {
    position: relative;
  }

  &__notification {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    + ._cookies {
      bottom: 48px;
      z-index: 100500;
    }
  }

  &__theme-background {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: @white;
    overflow: hidden;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 700px;
      height: 100%;
      background-repeat: repeat;
      background-size: auto;
    }

    &::after {
      right: calc(50% + 800px);
      background-image: url('/_ssr/img/backgrounds/snowflakes-pattern.png');
      background-position: right 0;

      @media @desktop {
        right: calc(100% - 22px);
      }

      @media @tablet {
        right: calc(100% - 42px);
      }
    }

    &::before {
      left: calc(50% + 800px);
      background-image: url('/_ssr/img/backgrounds/snowflakes-pattern.png');
      background-position: left 0;

      @media @desktop {
        left: calc(100% - 22px);
      }

      @media @tablet {
        left: calc(100% - 42px);
      }
    }

    @media @1600 {
      &::after,
      &::before {
        display: none;
      }
    }

    @media @1600 {
      display: none;
    }
  }
}
</style>
