<template>
  <div v-if="!isClosed" class="poster-container">
    <component
      :is="banner"
      :price="parameters.minimalDeliveryPrice"
      :delivery-price="parameters.deliveryPrice"
      :delivery-time="parameters.deliveryTime"
      :location-name="parameters.locationName"
      @close="closeBanner"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import FivePost from '@/components/AppPoster/PosterWithHtml/FivePost';
import Location from '@/components/AppPoster/PosterWithHtml/Location';

export default defineComponent({
  name: 'PosterHtml',
  components: {
    FivePost,
    Location,
  },
  props: {
    group: { type: String, default: null },
    parameters: { type: Object, default: null },
  },
  setup(props) {
    const localState = reactive({
      isClosed: false,
    });

    const banner = computed({
      get: () => {
        if (props.group === 'FIVE_POST') {
          return 'five-post';
        }
        if (props.group === 'LOCATION') {
          return 'location';
        }
      },
      set: () => {},
    });

    const closeBanner = () => {
      localState.isClosed = true;
    };

    return {
      ...toRefs(localState),
      banner,
      closeBanner,
    };
  },
});
</script>

<style lang="less">
.app-banner {
  background: url("~/static/_ssr/img/backgrounds/back-road-big.svg?inline") repeat-x;
  height: 168px;
  position: relative;

  &._hidden {
    display: none;
  }

  &__container {
    height: 100%;
  }

  &__content {
    height: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: #142d3b;
    line-height: 38px;
    text-align: left;

    &:hover {
      text-decoration: none;
    }
  }

  &__close {
    background: none;
    width: 29px;
    height: 29px;
    border: solid 1px #121b44;
    border-radius: 100%;
    position: absolute;
    right: 19px;
    top: 19px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      left: 5px;
      height: 1px;
      width: 18px;
      transform: rotate(45deg);
      background: #121b44;
    }

    &::after {
      content: '';
      position: absolute;
      left: 4px;
      height: 1px;
      width: 18px;
      transform: rotate(-45deg);
      background: #121b44;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 800;
    margin: 0;
  }

  &__text {
    font-size: 24px;
    margin: 0;
  }

  &__bike {
    margin-top: 33px;
    margin-right: 33px;
    width: 112px;
    height: 97px;
  }

  &__body {
    margin-top: 32px;
    padding-right: 45px;
  }

  // image mode
  &._image {
    background: none;
    height: auto;
    text-align: center;
    overflow: hidden;
  }

  &._image &__container {
    position: relative;
    text-align: center;
    left: 50%;
    margin-left: -50000px;
    width: 100000px;
    height: auto;
  }

  &._image &__content {
    text-decoration: none;
    color: inherit;
    text-align: center;
    vertical-align: top;
    display: inline-block;
  }

  &._image &__image {
    margin: 0;
    padding: 0;
    max-width: 100%;
    vertical-align: top;
    display: inline-block;
  }
}
</style>
