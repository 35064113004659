import { ref } from '@nuxtjs/composition-api';
import { captureException } from '@sentry/browser';

import useHttpClient from '@/compositions/useHttpClient';
import useUrl from '@/compositions/useUrl';

const useThroughBanner = () => {
  const { content } = useHttpClient();
  const url = useUrl();

  /**
   * @typedef {Object} Banner
   * @property {string|number} id
   * @property {string} name
   * @property {string} url
   * @property {string} imageOriginalSrc
   */

  /**
   * @type {Banner|null}
   */
  const banner = ref(null);

  const isLoading = ref(true);

  const removeBanner = () => {
    isLoading.value = false;
    banner.value = null;
  };

  /**
   * Возвращает список доступных баннеров
   * @param {Object} options
   * @param {string} options.typeBanner - тип баннера
   * @return {Promise<void>}
   */
  const getBanner = async (options = {}) => {
    const { typeBanner = null } = options;
    const currentUrl = url.getCurrentUrl();

    if (typeof typeBanner === 'string' && !!typeBanner.length) {
      isLoading.value = true;
      await content.getBanners({ type: typeBanner, url: currentUrl })
        .then((list) => {
          if (list.length) {
            const [firstItem] = list;

            if (firstItem) {
              if (['FIVE_POST', 'LOCATION'].includes(firstItem.group)) {
                banner.value = {
                  id: firstItem.id,
                  name: firstItem.name,
                  url: firstItem.url,
                  type: firstItem.group,
                  parameters: firstItem.parameters,
                };
              } else {
                const [image] = firstItem.images;

                if (image.src) {
                  banner.value = {
                    id: firstItem.id,
                    name: firstItem.name,
                    url: firstItem.url,
                    imageOriginalSrc: image.src,
                  };
                } else {
                  removeBanner();
                }
              }
            } else {
              removeBanner();
            }
          } else {
            removeBanner();
          }
        })
        .catch((error) => {
          banner.value = null;
          captureException(error);
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
  };

  return {
    banner,
    isLoading,
    getBanner,
    removeBanner,
  };
};

export default useThroughBanner;
