<script>
import { checkMobile } from '@/utils/deviceCheck';

import desktop from '@/components/AppPoster/PosterDelivery/desktop';
import mobile from '@/components/AppPoster/PosterDelivery/mobile';

export default {
  name: 'ProductNotAvailable',
  functional: true,
  render(createElement, context) {
    return createElement(
      checkMobile() ? mobile : desktop,
      context.data,
      context.children,
    );
  },
};
</script>
