<template>
  <footer-one :title="text" :mods="'_copyright'" />
</template>

<script>
import FooterOne from '@/components/AppFooter/FooterOne';

export default {
  name: 'Copyright',
  components: {
    FooterOne,
  },
  data() {
    return {
      text: `© 2016-${new Date().getFullYear()}, ООО «Новый Книжный Центр».
       Использование материалов сайта возможно только с активной ссылкой на book24.ru.`,
    };
  },
};
</script>
