<template>
  <div class="search-suggestions">
    <div v-if="title" class="search-suggestions__title">
      {{ title }}
    </div>
    <ul class="search-suggestions__list">
      <li class="search-suggestions__list-item" style="padding: 4px 16px;">
        <content-placeholders rounded>
          <content-placeholders-img style="height: 15px; width: 120px;" />
          <content-placeholders-img style="height: 15px; width: 100px;" />
          <content-placeholders-img style="height: 15px; width: 90px;" />
          <content-placeholders-img style="height: 15px; width: 130px;" />
        </content-placeholders>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SearchSuggestionsSkeleton',
  props: {
    title: { type: String, default: '' },
  },
};
</script>
