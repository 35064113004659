<template>
  <div
    v-click-outside="closeMenu"
    :class="{ _authorized: isAuthorized }"
    class="user-menu"
  >
    <button
      v-if="!isAuthorized"
      class="user-menu__auth-btn"
      @click.prevent="showLogin"
    >
      <span class="user-menu__auth-label">
        Вход
      </span>
      <span class="user-menu__auth-divider">
        /
      </span>
      <span class="user-menu__auth-label">
        Регистрация
      </span>
    </button>
    <div v-else class="user-menu__profile" :class="{_active: isOpenMenu}">
      <button class="user-menu__button" @click="toggleMenu">
        <span class="user-menu__title">
          Личный кабинет
          <alert-icon
            v-if="isLoyaltyMismatches"
            class="user-menu__alert-icon"
          />
          <svg-icon
            class="user-menu__icon"
            :class="{_active: isOpenMenu}"
            name="16/Arrow-down_16"
            width="8"
            height="8"
          />
        </span>
        <skeleton-part-user-menu v-if="!isUserLoaded" />
        <span v-else class="user-menu__info">
          Бонусов: <mark class="user-menu__value">{{ userLoyalty ? userLoyalty.balance : 0 }}</mark>
          Кэшбэк: <mark class="user-menu__value">
            {{ userLoyalty ? userLoyalty.percentCashback : 0 }}
          </mark>
          <span
            v-if="showNearestExpiration"
            class="user-menu__nearest-expiration-icon"
          >
            <news-icon />
          </span>
        </span>
      </button>
      <ul v-show="isOpenMenu" class="user-menu__menu-list">
        <app-nearest-expiration-bonuses
          :date="userLoyalty.nearestExpirationDate"
          :value="userLoyalty.nearestExpiration"
          @on-show="toggleNearestExpiration"
        />
        <li
          v-for="item in menu"
          :key="item.name"
          class="user-menu__menu-item"
          @click="toggleMenu"
        >
          <app-link
            class="user-menu__link"
            :href="item.url"
          >
            {{ item.name }}
            <alert-icon
              v-if="item.isAlert"
              class="user-menu__alert-icon-link"
            />
          </app-link>
        </li>
        <li class="user-menu__menu-item">
          <button type="button" class="user-menu__link" @click.prevent="clickLogoutHandler">
            Выйти
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api';

import { mapState, mapActions } from 'vuex';
import { CURRENCY } from '@/utils/constants/globalConst';
import formatNum from '@/utils/formatNum';

import useAuthorization from '@/compositions/authorization/useAuthorization';

import SkeletonPartUserMenu from '@/components/header/UserMenu/SkeletonPartUserMenu';

// icons
import AlertIcon from '@/assets/sprite/svg/16/Alert_16.svg';
import NewsIcon from '@/assets/sprite/svg/24/News_24.svg';
import AppNearestExpirationBonuses from '@/components/AppNearestExpirationBonuses/index.vue';

export default defineComponent({
  name: 'UserMenu',
  components: {
    AppNearestExpirationBonuses,
    SkeletonPartUserMenu,
    AlertIcon,
    NewsIcon,
  },
  setup() {
    const store = useStore();

    const authorization = useAuthorization();
    const user = computed(() => store.state.user);

    const isLoyaltyMismatches = computed(() => (!!user.value.isLoyaltyMismatches));

    const menu = computed(() => [
      { name: 'Мои заказы', url: '/personal/order/' },
      { name: 'Рекомендуем вам', url: '/personal/recommendation/' },
      { name: 'Отложенные', url: '/personal/my-library/' },
      { name: 'Бонусный счёт', url: '/personal/cashback/' },
      { name: 'Личные данные', url: '/personal/my-profile/', isAlert: isLoyaltyMismatches.value },
    ]);

    return {
      ...authorization,
      isLoyaltyMismatches,
      user,
      menu,
    };
  },
  data() {
    return {
      isOpenMenu: false,
      showNearestExpiration: false,
    };
  },
  computed: {
    ...mapState({
      isAuthorized: store => store.isAuthorized,
    }),
    isUserLoaded() {
      return Object.keys(this.user).length;
    },
    userLoyalty() {
      const balance = formatNum(this?.user?.relationships?.userLoyalty?.balance || 0);
      const percentCashback = this?.user?.relationships?.userLoyalty?.percentCashback || 0;
      const nearestExpiration = this?.user?.relationships?.userLoyalty?.closestUpcomingExpiration?.amount || '';
      const nearestExpirationDate = this?.user?.relationships?.userLoyalty?.closestUpcomingExpiration?.date || '';
      return {
        balance: `${balance} ${CURRENCY}`,
        percentCashback: `${percentCashback}%`,
        nearestExpiration,
        nearestExpirationDate,
      };
    },
  },
  methods: {
    ...mapActions(['logout']),
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },
    toggleNearestExpiration(value) {
      this.showNearestExpiration = value;
    },
    closeMenu() {
      this.isOpenMenu = false;
    },
    showLogin() {
      this.openAuthorizationModal('Header');
    },
    clickLogoutHandler() {
      this.logout();
    },
  },
});
</script>

<style lang="less">
.user-menu {
  position: relative;
  min-width: 165px;

  &._authorized {
    min-width: max-content;
  }

  &__button {
    .button-reset;

    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: @black;
    z-index: 3;
    position: relative;
  }

  &__title {
    .small;

    font-weight: 600;
    color: @link;
    display: flex;
    align-items: center;
    transition: @A color;
  }

  &__button:hover &__title,
  &__button:focus &__title {
    color: @black;
  }

  &__icon {
    fill: @link;
    margin-left: 5px;
    margin-top: 2px;
    transition: @A fill, @A transform;

    &._active {
      transform: rotate(180deg);
    }
  }

  &__alert-icon {
    margin: 0 @size-x1;
  }

  &__alert-icon-link {
    margin-left: @size-x2;
  }

  &__nearest-expiration-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: @size-x6;
    height: @size-x6;
    padding: @size-x1;
    margin-left: 6px;
    background: @pink-back;
    border-radius: 100%;

    svg {
      fill: @pink;
    }
  }

  &__info {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: normal;
    color: @black;
    display: flex;
    align-items: center;
  }

  &__menu-list {
    position: absolute;
    top: -13px;
    left: -17px;
    right: -17px;
    z-index: 2;
    background-color: @white;
    border: solid 1px #d8dcde;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    padding: 64px 0 0;
    opacity: 1;
    visibility: visible;
    overflow: hidden;
    transition: @A box-shadow, @A opacity, @A visibility;
    margin: 0;

    &._hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__menu-item {
    &:first-child {
      border-top: 1px solid #e6e7eb;
    }
  }

  &__link {
    .a-reset;
    .button-reset;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;
    color: @black;
    transition: @A color, @A background-color;

    &:hover,
    &._tap {
      background-color: #f2f3f7;
      color: @link;
    }
  }

  &__auth-btn {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    letter-spacing: normal;
    transition: @A color, @A border-bottom-color;
    cursor: pointer;

    &:hover,
    &._tap {
      color: @black;
      border-bottom-color: @black;
    }
  }

  &__auth-label {
    .small(19px);

    font-weight: 600;
    color: @link;
    border-bottom: 1px dashed @link;
  }

  &__auth-divider {
    .small(20px);

    margin: 0 7px;
    color: #d8dcde;
    letter-spacing: normal;
  }

  &__value {
    background: none;
    color: @link;
    white-space: nowrap;
    margin-left: 3px;
    margin-right: 3px;
  }
}
</style>
