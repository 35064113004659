<template>
  <span itemscope itemtype="https://schema.org/PostalAddress">
    <meta itemprop="postalCode" content="123308" />
    <meta itemprop="addressLocality" content="г. Москва" />
    <meta itemprop="streetAddress" content="ул. Зорге, дом № 1, стр.1" />
  </span>
</template>

<script>
export default {
  name: 'PostalAddress',
};
</script>
