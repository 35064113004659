import { computed, ref, useStore } from '@nuxtjs/composition-api';
import useHttpClient from '@/compositions/useHttpClient';
import {
  getRecommendationsSave,
  saveDate,
  saveListId,
  getDateSaveRecommendations,
} from '@/utils/recommendationProductsUser';

import useProductsRecommendation from '@/compositions/shelfs/useProductsRecommendation';

// 20 минут
const INTERVAL = 1000 * 60 * 20;
// 1 минута
const DELAY_TIMER = 1000 * 60;

const useRecommendationsUser = () => {
  const store = useStore();
  const recommendations = useProductsRecommendation();
  const { recommendation } = useHttpClient();

  const isHidden = ref(false);

  let timer = null;

  const isAuthorized = computed({
    get: () => store.state.isAuthorized,
    set: () => {},
  });

  const updateHasRecommendations = async () => {
    await recommendations.getProductsRecommendation({
      pageSize: 100,
      limit: null,
    });
    store.dispatch(
      'setHasRecommendationsUser',
      !!recommendations.productsRecommendation.value.length,
    );
  };

  const saveRecommendations = async () => {
    try {
      await updateHasRecommendations();
      saveListId(recommendations.productsRecommendation.value.map(item => item.id));
      saveDate();
    } catch (e) {
      saveListId([]);
      saveDate();
    }
  };

  const clearTimer = () => {
    clearTimeout(timer);
  };

  const setTimer = () => {
    timer = setTimeout(async function run() {
      const lastDate = getDateSaveRecommendations();

      if (!lastDate) {
        await saveRecommendations();
      }

      const currentDate = new Date();
      const diffTime = lastDate ? ((currentDate - new Date(lastDate)) / INTERVAL) : 0;

      if (diffTime > 1) {
        await saveRecommendations();
        clearTimer();
      }
      timer = setTimeout(run, DELAY_TIMER);
    }, DELAY_TIMER);
  };

  const saveRecoInit = async () => {
    if (isAuthorized.value) {
      const date = getDateSaveRecommendations();
      const savedResult = getRecommendationsSave();
      if (!savedResult.length || !date) {
        await saveRecommendations();
        setTimer();
      } else {
        setTimer();
      }
    }
  };

  const hideProduct = (type, productId) => {
    if (type && productId) {
      recommendation.sendProductAttraction({
        productId,
        type,
      });
    }

    isHidden.value = true;
  };

  const recoverProduct = (productId) => {
    recommendation.sendProductAttraction({
      productId,
      type: 'cancel',
    });
    isHidden.value = false;
  };

  const rateProduct = (productId, rate) => {
    recommendation.sendProductAttraction({
      productId,
      rate,
      type: 'rating',
    });
    isHidden.value = false;
  };

  const recoveryWithSaveRate = (rate, productId) => {
    if (rate) {
      rateProduct(productId, rate);
      return;
    }

    recoverProduct(productId);
  };

  return {
    isHidden,
    saveRecoInit,
    clearTimer,
    saveRecommendations,
    getRecommendationsSave,
    getDateSaveRecommendations,
    hideProduct,
    recoverProduct,
    rateProduct,
    recoveryWithSaveRate,
  };
};

export default useRecommendationsUser;
