<template>
  <div v-if="!isMobile" class="app-banner _post-delivery">
    <div class="app-banner__container">
      <button
        class="app-banner__close"
        type="button"
        @click="$emit('close')"
      ></button>
      <div class="app-banner__content">
        <div class="app-banner__body">
          <p class="app-banner__title">
            Бесплатная доставка от {{ price }} руб
          </p>
          <p class="app-banner__text">
            В пункты выдачи 5post в "Пятёрочке"
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="app-banner-mobile _post-delivery">
    <div class="app-banner-mobile__container">
      <button
        class="app-banner-mobile__close"
        @click="$emit('close')"
      >
        <close24-icon class="app-banner-mobile__icon" />
      </button>
      <div class="app-banner-mobile__content">
        <div class="app-banner-mobile__body">
          <p class="app-banner-mobile__title">
            Бесплатная доставка от {{ price }} руб
          </p>
          <p class="app-banner-mobile__text">
            В пункты выдачи 5post в "Пятёрочке"
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close24Icon from '@/assets/sprite/svg/24/Close_24.svg';

export default {
  name: 'FivePost',
  components: {
    Close24Icon,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
