<template>
  <span class="user-menu__info">
    <content-placeholders rounded style="height: 19px; display: flex; align-items: center;">
      <content-placeholders-img style="height: 12px; width: 160px; border-radius: 6px;" />
    </content-placeholders>
  </span>
</template>

<script>
export default {
  name: 'SkeletonPartUserMenu',
};
</script>
