<template>
  <ul class="promo-menu">
    <li v-for="item in promoMenuPersonal" :key="item.name" class="promo-menu__item">
      <app-link class="promo-menu__link" :href="item.url">
        <component
          :is="item.icon"
          :class="[item.modClass]"
          class="promo-menu__icon"
        />
        {{ item.name }}
      </app-link>
    </li>
  </ul>
</template>

<script>

// icons
import MyRecommendations16Icon from '@/assets/sprite/svg/16/My_recommendations_16.svg';
import Compilations16Icon from '@/assets/sprite/svg/16/Compilations_16.svg';
import Like16Icon from '@/assets/sprite/svg/16/Like_16.svg';
import Heart24Icon from '@/assets/sprite/svg/24/Heart_24.svg';
import Sales16Icon from '@/assets/sprite/svg/16/Sales_16.svg';
import Gift16Icon from '@/assets/sprite/svg/16/Gift_16.svg';

export default {
  components: {
    MyRecommendations16Icon,
    Compilations16Icon,
    Like16Icon,
    Heart24Icon,
    Sales16Icon,
    Gift16Icon,
  },
  data() {
    return {
      promoMenu: [
        {
          name: 'Рекомендуем вам',
          url: '/personal/recommendation',
          icon: 'MyRecommendations16Icon',
          modClass: '_green',
          personal: true,
        },
        {
          name: 'Лучшие цены',
          url: '/best-price/',
          icon: 'Like16Icon',
          modClass: '_red',
        },
        {
          name: 'Авторы',
          url: '/author/',
          icon: 'Compilations16Icon',
          modClass: '_yellow',
        },
        {
          name: 'Рекомендации знаменитостей',
          url: '/people-and-books/',
          icon: 'Heart24Icon',
          modClass: '_red',
        },
        {
          name: 'Товары со скидкой',
          url: '/catalog-sale/',
          icon: 'Sales16Icon',
          modClass: '_yellow',
        },
        {
          name: 'Подарки',
          url: '/podarki/',
          icon: 'Gift16Icon',
          modClass: '_red',
        },
      ],
    };
  },
  computed: {
    promoMenuPersonal() {
      return !this.$store.state.isAuthorized ? this.promoMenu.filter(el => el.name !== 'Рекомендуем вам') : this.promoMenu;
    },
  },
};
</script>

<style lang="less">
.promo-menu {
  display: flex;
  margin: 0;
  border-bottom: 1px solid @gray-light;
  list-style: none;
  padding: @size-x4 0 @size-x3;
  width: 100%;

  &__item {
    .small;

    margin-right: 32px;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: @black;

    &:hover {
      color: @link-hover;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    &._red {
      fill: @pink;
    }

    &._yellow {
      fill: @orange-warning;
    }

    &._green {
      fill: @green;
    }

    &._gray {
      fill: @gray;
    }
  }
}
</style>
