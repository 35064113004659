<template>
  <form
    v-click-outside="closeSuggestions"
    action="/search/"
    method="get"
    class="search-new-d"
    autocomplete="off"
    @keyup.prevent.down="down"
    @keyup.prevent.up="up"
  >
    <app-search-field
      ref="search"
      v-model="query"
      class="search-new-d__field"
      placeholder="Поиск книг, авторов"
      :loading="isLoading"
      :class="{'_focus': isShow}"
      @focus="focus"
      @find="submit"
      @clear="clearInputHandler"
    />
    <div v-if="isShow" class="search-new-d__results">
      <search-suggestions
        v-if="results.length"
        v-show="query"
        :results="results"
        :query="query"
        :active="hoveredProductId"
        @clickHandler="suggestionsClickHandler($event, true)"
      />
      <template v-if="!results.length || !query">
        <search-suggestions
          v-if="history.length"
          :results="history"
          :active="hoveredProductId"
          title="История поиска"
          is-history
          @clearHistory="clearHistory"
          @clickHandler="suggestionsClickHandler"
        />
        <search-suggestions-skeleton v-if="isLoadingPopular" title="Популярные запросы" />
        <search-suggestions
          v-else-if="popular.length"
          :results="popular"
          :active="hoveredProductId"
          :is-loading="isLoadingPopular"
          title="Популярные запросы"
          @clickHandler="suggestionsClickHandler($event, true)"
        />
      </template>
    </div>
  </form>
</template>

<script>
import debounce from 'lodash.debounce';
import { captureException } from '@sentry/browser';

import { getLocalItem, setLocalItem } from '@/utils/paramsLocalStorage';
import {
  SEARCH_HISTORY,
  SEARCH_URL_PART,
  SEARCH_QUERY,
} from '@/utils/constants/searchConst';

import SearchSuggestions from '@/components/_desktop/AppHeader/TheSearch/SearchSuggestions';
import AppSearchField from '@/components/AppSearchField';
import SearchSuggestionsSkeleton from '@/components/_desktop/AppHeader/TheSearch/SearchSuggestions/skeleton';

export default {
  name: 'TheSearch',
  serverCacheKey: () => 'TheSearch',
  components: {
    SearchSuggestionsSkeleton,
    AppSearchField,
    SearchSuggestions,
  },
  data() {
    return {
      isShow: false,
      query: '',
      results: [],
      popular: [],
      history: [],
      isLoading: false,
      isLoadingPopular: false,
      hoveredProductId: null,
    };
  },
  computed: {
    combinedSuggestions() {
      return this.query
        ? this.results
        : [
          ...this.history,
          ...this.popular,
        ];
    },
    hoveredProductIdx() {
      return this.hoveredProductId !== null
        ? this.combinedSuggestions.findIndex(i => i.id === this.hoveredProductId)
        : -1;
    },
  },
  watch: {
    query(value, oldValue) {
      if (value && value !== oldValue && value.length >= 3) {
        this.getSuggestions(value);
      }
    },
    $route() {
      this.getQueryFromUrl();
    },
  },
  beforeMount() {
    this.getQueryFromUrl();
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    /**
     * очищаем query на событие clear в input что бы не уходил лишний запрос
     */
    clearInputHandler() {
      this.query = '';
    },
    /**
     * подставляем значение из роута поиска в query
     */
    getQueryFromUrl() {
      this.query = this.$route.name === SEARCH_QUERY ? this.$route.query.q : '';
    },
    /**
     * Мапим данные для массива SearchSuggestionSimple
     * @param data
     */
    mapSuggestionData(data) {
      return data.map((item, key) => {
        let isStringsArr = false;
        if (typeof item === 'string') isStringsArr = true;
        // если история поиска
        if (isStringsArr) {
          return {
            title: item,
            id: key,
            link: `${SEARCH_URL_PART}${item}`,
          };
        }
        // если популярные запросы
        if (item.phrase) {
          return {
            title: item.phrase,
            id: item.id,
            link: `${SEARCH_URL_PART}${item.phrase}`,
          };
        }
        // для suggestions
        return {
          id: key,
          ...item,
        };
      });
    },
    /**
     * получаем данные истории поиска с localStorage
     */
    getHistory() {
      const historyParams = getLocalItem(SEARCH_HISTORY);
      this.history = historyParams.length ? this.mapSuggestionData(historyParams) : [];
    },
    /**
     * добавляем данные истории поиска в localStorage
     */
    setHistory(setHistoryArr = null) {
      setLocalItem(SEARCH_HISTORY,
        setHistoryArr || this.history.map(item => item.title));
      if (setHistoryArr) this.getHistory();
    },
    /**
     * добавляем и кропаем список Истории в localStorage
     * @param value
     */
    addToHistoryLocalStorage(value) {
      const formattedValue = value.toLowerCase();
      const setHistoryArr = this.history ? this.history.map(item => item.title) : [];
      if (!setHistoryArr.includes(formattedValue)) {
        if (setHistoryArr.length > 3) setHistoryArr.pop();
        setHistoryArr.unshift(formattedValue);
        this.setHistory(setHistoryArr);
      }
    },
    /**
     * очищаем историю поиска в localStorage
     */
    clearHistory(key = null) {
      if (typeof key !== 'number') {
        this.history = [];
      } else {
        this.history.splice(key, 1);
      }
      this.setHistory();
    },
    /**
     * Запрос Suggestions
     */
    getSuggestions: debounce(async function getSuggestionsFunc(q) {
      if (q) {
        this.isLoading = true;
        this.hoveredProductId = null;
        try {
          const response = await this.$httpClient.catalog.getSuggestions({
            q,
            limitSuggest: 5,
            limitSection: 3,
            limitProduct: 5,
            limitPodborki: 0,
          });
          this.results = this.mapSuggestionData(response);
        } finally {
          this.isLoading = false;
        }
      }
    }, 400),
    /**
     * получаем популярные фразы
     */
    async getPopularPhrases() {
      try {
        this.isLoadingPopular = true;
        const response = await this.$httpClient.catalog.getPopularPhrases();
        if (response) this.popular = this.mapSuggestionData(response);
      } catch (error) {
        captureException(error);
      } finally {
        this.isLoadingPopular = false;
      }
    },
    /**
     * на фокус делаем запрос Suggestions
     */
    focus() {
      this.isShow = true;

      if (this.query) {
        this.getSuggestions(this.query);
      }

      if (!this.query && !this.popular.length) {
        this.getPopularPhrases();
      }
    },
    /**
     * скрываем выдачу
     */
    closeSuggestions() {
      this.isShow = false;
      this.$refs.search.resetFocus();
      this.results = [];
      this.hoveredProductId = null;
    },
    down() {
      let idx = this.hoveredProductIdx;

      if (idx >= this.combinedSuggestions.length - 1) {
        idx = 0;
      } else {
        idx += 1;
      }

      this.hoveredProductId = this.combinedSuggestions[idx].id;
    },
    up() {
      let idx = this.hoveredProductIdx;
      if (idx <= 0) {
        idx = this.combinedSuggestions.length - 1;
      } else {
        idx -= 1;
      }

      this.hoveredProductId = this.combinedSuggestions[idx].id;
    },
    /**
     * отправка поиска
     */
    async submit() {
      this.query = this.query.trim();
      if (this.query !== this.$route.query?.q) {
        let historyPushValue = null;
        let routerPushValue = null;

        if (this.hoveredProductId && this.combinedSuggestions.length) { // if the product is selected — go the that product
          const hoveredProduct = this.combinedSuggestions[this.hoveredProductIdx];
          historyPushValue = hoveredProduct.title;
          routerPushValue = hoveredProduct.link;
        } else { // otherwise, submit the search form
          historyPushValue = this.query;
          routerPushValue = {
            path: '/search/',
            query: { q: this.query },
          };
        }

        if (historyPushValue) {
          this.addToHistoryLocalStorage(historyPushValue);
        }
        if (routerPushValue) {
          this.$router.push(routerPushValue);
        }
        this.closeSuggestions();
      }
    },
    /**
     * события по клику на ссылку в выдаче suggestions
     * @param title
     * @param url
     * @param isAddToHistory
     */
    suggestionsClickHandler({ title, url }, isAddToHistory = false) {
      // если урл содержит SEARCH_URL_PART то значение поиска равно title
      // иначе очищаем поиск
      if (url && url.includes(SEARCH_URL_PART)) {
        this.query = title;
      } else {
        this.query = '';
      }
      // добавляем тайтл в историю поиска
      if (isAddToHistory && title) this.addToHistoryLocalStorage(title);
      // закрываем выдачу поиска
      this.closeSuggestions();
    },
  },
};
</script>

<style lang="less">
.search-new-d {
  position: relative;
  width: 100%;

  &__wrapper {
    position: relative;
    display: flex;
  }

  &__results {
    display: block;
    position: absolute;
    width: 100%;
    z-index: 3;
    background-color: @white;
    border: 1px solid @gray-light;
    border-radius: 0 0 @main-size @main-size;
  }
}
</style>
