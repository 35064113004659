import {
  provide,
  ref,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';

const usePrevRouteLink = () => {
  const route = useRoute();

  const prevRouteLink = ref(null);
  provide('prevRouteLink', prevRouteLink);

  watch(
    route,
    (value, oldValue) => {
      prevRouteLink.value = oldValue.fullPath;
    },
    { immediate: false },
  );

  return { prevRouteLink };
};

export default usePrevRouteLink;
