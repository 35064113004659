<template>
  <component
    :is="isMobile ? 'span' : 'li'"
    v-if="isShow"
    :class="{ _mobile: isMobile }"
    class="app-nearest-expiration-bonuses"
  >
    <span class="app-nearest-expiration-bonuses__date">
      {{ nearestExpirationDate }}
    </span>
    <span class="app-nearest-expiration-bonuses__value">
      {{ nearestExpiration }}
    </span>
  </component>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watchEffect,
} from '@nuxtjs/composition-api';

import formatNum from '@/utils/formatNum';
import { getDifferenceBetweenDateTypes } from '@/utils/dateTime';
import plural from '@/utils/plural';

export default defineComponent({
  name: 'AppNearestExpirationBonuses',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
      required: true,
    },
    value: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup(props, { emit }) {
    const daysLeft = ref(0);

    const nearestExpiration = computed(() => {
      const value = formatNum(props.value);
      return value ? `${value} ${plural(value, ['бонус', 'бонуса', 'бонусов'])}` : 0;
    });

    const nearestExpirationDate = computed(() => {
      const date = new Date(props.date);
      daysLeft.value = getDifferenceBetweenDateTypes(date, new Date(), 'calendarDays') || 1;
      return daysLeft.value
        ? `Через ${daysLeft.value} ${plural(daysLeft.value, ['день', 'дня', 'дней'])} ${plural(nearestExpiration.value, ['сгорит', 'сгорят', 'сгорят'])}`
        : 0;
    });

    const isShow = ref(false);

    watchEffect(() => {
      isShow.value = !!(props.date && props.value && daysLeft.value < 10);
      emit('on-show', isShow.value);
    });

    return {
      isShow,
      nearestExpiration,
      nearestExpirationDate,
    };
  },
});
</script>

<style lang="less">
.app-nearest-expiration-bonuses {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 @size-x4 @size-x4;
  border-bottom: 1px solid #e6e7eb;
  color: @orange;

  &__date {
    .extra-small(16px);

    display: inline-block;
    margin-bottom: 3px;
  }

  &__value {
    .small(16px);

    font-weight: 600;
  }

  &._mobile {
    padding: 10px 0 @size-x2;
    border-bottom: none;
  }
}
</style>
