<template>
  <div class="footer-one" :class="mods">
    <div v-if="title" class="footer-one__title">
      {{ title }}
    </div>
    <div class="footer-one__content">
      <component
        :is="item.link ? 'a' : 'div'"
        v-for="item in modifierList"
        :key="`footer-icon-${item.id}`"
        class="footer-one__icon-box"
        :class="{_link: item.link}"
        v-bind="getBind(item)"
      >
        <component
          :is="item.icon.component"
          :width="item.icon.width"
          :height="item.icon.height"
          class="footer-one__icon"
        />
      </component>
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';

export default {
  name: 'FooterOne',
  props: {
    title: { type: String, default: '' },
    mods: { type: String, default: '' },
    list: { type: Array, default: () => ([]) },
  },
  computed: {
    modifierList() {
      return this.list.map(item => ({ ...item, id: this.getId() }));
    },
  },
  methods: {
    getId() {
      return nanoid();
    },
    getBind(item) {
      const attrs = {};

      if (item.link) {
        attrs.target = '_blank';
        attrs.rel = 'noreferrer noopener nofollow';
        attrs.href = item.link;
      }

      return attrs;
    },
  },
};
</script>

<style lang="less">
.footer-one {
  &__title {
    font-size: 13px;
    line-height: 18px;
    color: @black;
    margin: 0;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__icon-box {
    margin-top: 10px;

    &._link {
      .a-reset;
    }
  }

  &._payment &__icon-box {
    margin-right: @size-x5;

    &:last-child {
      margin-right: 0;
    }
  }

  &._socials &__icon-box {
    margin-right: @size-x4;

    &:last-child {
      margin-left: 0;
    }
  }

  &._socials &__content {
    max-width: 290px;
  }
}
</style>
