<template>
  <div
    class="poster"
    :class="{ ddl_campaign: addDdl }"
    v-bind="rootBindAttrs"
  >
    <component
      :is="componentContainerImage"
      class="poster__container"
      :class="{ ddl_campaign_link: addDdl }"
      v-bind="componentBindAttrs"
    >
      <app-picture
        :item="item"
        :is-lazy-load="isLazyLoad"
        :alt="alt"
        :title="title"
        :not-use-fallback="notUseFallback"
        :fallback-image="fallbackImage"
        class-img="poster__image"
        class-picture="poster__picture"
        :lazy-load-class-name="lazyLoadClassName"
      />
    </component>
  </div>
</template>

<script>
import AppPicture from '@/components/AppPicture';
import { productImage } from '@/utils/constants/catalogConstants';

export default {
  name: 'AppPoster',
  components: { AppPicture },
  props: {
    linkParent: { type: String, default: '' },
    addDdl: { type: Boolean, default: false },
    campaignId: { type: [String, Number], default: null },
    fallbackImage: { type: Object, default: () => (productImage) },
    item: { type: Object, required: true },
    alt: { type: String, default: '' },
    title: { type: String, default: '' },
    isLazyLoad: { type: Boolean, default: true },
    notUseFallback: { type: Boolean, default: false },
    lazyLoadClassName: { type: String, default: 'lazyload' },
  },
  computed: {
    componentContainerImage() {
      return this.linkParent ? 'app-link' : 'span';
    },
    rootBindAttrs() {
      const defaultAttrs = {};

      if (this.addDdl) {
        defaultAttrs['data-campaign-id'] = this.campaignId;
      }

      return defaultAttrs;
    },
    componentBindAttrs() {
      const defaultAttrs = {};

      if (this.addDdl) {
        defaultAttrs['data-campaign-id'] = this.campaignId;
      }
      if (this.linkParent) {
        Object.assign(defaultAttrs, {
          href: this.linkParent,
          title: this.title,
        });
      }

      return defaultAttrs;
    },
  },
};
</script>

<style lang="less">
.poster {
  text-align: center;
  overflow: hidden;
  position: relative;

  &__container {
    position: relative;
    text-align: center;
    left: 50%;
    margin-left: -1500px;
    width: 3000px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  a&__container {
    .a-reset;

    height: 100%;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__image {
    max-width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
</style>
