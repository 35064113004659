<template>
  <nav v-click-outside="closeMenu" class="main-menu">
    <menu-general :is-open-menu="isOpenMenu" :is-loading="isMenuLoading" @toggleMenu="toggleMenu" />
    <div v-if="isOpenMenu" class="main-menu__dropdown">
      <div class="main-menu__row">
        <promo-menu />
      </div>
      <div class="main-menu__row">
        <loader-block v-if="isMenuLoading" text="" />
        <menu-first-panel @activeMenu="setActive" />
        <menu-sub-panel :submenu="submenu" />
      </div>
    </div>
  </nav>
</template>

<script>
import { LoaderBlock } from '@book24/ui-components';

import MenuGeneral from '@/components/_desktop/AppHeader/MainMenu/MenuGeneral';
import PromoMenu from '@/components/_desktop/AppHeader/MainMenu/PromoMenu';
import MenuFirstPanel from '@/components/_desktop/AppHeader/MainMenu/MenuFirstPanel';
import MenuSubPanel from '@/components/_desktop/AppHeader/MainMenu/MenuSubPanel';

export default {
  name: 'MainMenu',
  serverCacheKey: () => 'MainMenu',
  components: {
    MenuSubPanel,
    MenuGeneral,
    MenuFirstPanel,
    PromoMenu,
    LoaderBlock,
  },
  data() {
    return {
      isOpenMenu: false,
      isMenuLoading: false,
      submenu: {},
    };
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.closeMenu();
      next();
    });
  },
  methods: {
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
      if (!this.$store.state.main.catalogMenu.length) {
        this.isMenuLoading = true;
        this.$store.dispatch('main/getCatalogMenu')
          .finally(() => {
            this.isMenuLoading = false;
          });
      }
    },
    setActive(value) {
      this.submenu = value;
    },
    closeMenu() {
      this.isOpenMenu = false;
    },
  },
};
</script>

<style lang="less">
.main-menu {
  position: relative;

  &__dropdown {
    box-shadow: 0 @main-size @size-x2 rgba(0, 0, 0, 0.15);
    background: @white;
    opacity: 1;
    visibility: visible;
    transition: @A opacity;
    padding: 0 24px;
    position: absolute;
    z-index: 9;
    top: 100%;
    left: 0;
    right: 0;
  }

  &__row {
    position: relative;
    display: flex;
  }
}
</style>
