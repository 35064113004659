<template>
  <div
    v-if="isShow"
    :class="{ _mobile: isMobile }"
    class="app-cookies-notification"
  >
    <div class="app-cookies-notification__container">
      <p class="app-cookies-notification__text">
        Этот сайт использует файлы cookie и другие технологии, чтобы помочь вам в навигации,
        а также предоставить лучший пользовательский опыт, анализировать использование наших
        продуктов и услуг, повысить качество наших предложений. Продолжая пользоваться сайтом,
        вы <app-link href="/terms/cookie/">
          соглашаетесь на обработку cookies.
        </app-link>
      </p>
      <button-control
        :is-block="isMobile"
        :loading="isLoading"
        class="app-cookies-notification__accept"
        @click="acceptHandler"
      >
        Принять
      </button-control>
    </div>
  </div>
</template>

<script>
import { defineComponent, useContext, ref, useStore, computed } from '@nuxtjs/composition-api';

import { ButtonControl } from '@book24/ui-components';

import getOptionsSetCookie from '@/utils/getOptionsSetCookie';
import { checkMobile } from '@/utils/deviceCheck';

import useHttpClient from '@/compositions/useHttpClient';

export default defineComponent({
  name: 'AppCookiesNotification',
  components: {
    ButtonControl,
  },
  setup() {
    const { $cookies, $config } = useContext();
    const { user } = useHttpClient();
    const store = useStore();

    const isMobile = checkMobile();

    const isShow = ref(true);
    const isLoading = ref(false);

    const isAuthorized = computed(() => (!!store.state.isAuthorized));

    const isCookiePoliceAgree = computed(() => (!!store.state.user?.isCookiePoliceAgree));

    const acceptHandler = async () => {
      if (!isCookiePoliceAgree.value && isAuthorized.value) {
        isLoading.value = true;
        await user.confirmCookie();
        isLoading.value = false;
      }

      $cookies.set('COOKIES_ACCEPTED', 'Y', getOptionsSetCookie($config.domainCookie));
      isShow.value = false;
    };

    return {
      isShow,
      isMobile,
      isLoading,
      acceptHandler,
    };
  },
});
</script>

<style lang="less">
.app-cookies-notification {
  padding-top: @size-x4;
  padding-bottom: @size-x4;
  background: @white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 100502;

  &__container {
    .container-d;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: @size-x10;
    min-width: unset;
  }

  &__text {
    .small;

    margin: 0;
  }

  &__accept {
    width: 155px;
    flex-shrink: 0;
  }

  &._mobile {
    padding-top: @size-x3;
    padding-bottom: @size-x3;
  }

  &._mobile &__container {
    .container-m;

    flex-direction: column;
    gap: @size-x3;
  }
}
</style>
