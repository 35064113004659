import { computed, provide, useStore } from '@nuxtjs/composition-api';

import useRecommendationsUser from '@/compositions/user/useRecommendationsUser';

const useRecommendationsLayout = () => {
  const store = useStore();
  const recommendationsUser = useRecommendationsUser();

  const isAuthorized = computed({
    get: () => store.state.isAuthorized,
    set: () => {},
  });

  const updateHasRecommendations = async () => {
    if (isAuthorized.value) {
      await recommendationsUser.saveRecommendations();
    }
  };
  provide('updateHasRecommendations', updateHasRecommendations);
  provide('getRecommendationsSave', recommendationsUser.getRecommendationsSave);
  provide('getDateSaveRecommendations', recommendationsUser.getDateSaveRecommendations);

  const saveRecoInit = async () => {
    if (isAuthorized.value) {
      await recommendationsUser.saveRecoInit();
    }
  };

  return {
    updateHasRecommendations,
    saveRecoInit,
  };
};

export default useRecommendationsLayout;
