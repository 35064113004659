<template>
  <footer class="footer">
    <div class="footer__menus">
      <footer-d-menu class="footer__container" />
    </div>
    <div class="footer__info">
      <div class="footer__box footer__container">
        <socials-list class="footer__one" />
        <payment-list class="footer__one" />
        <copyright class="footer__one" />
      </div>
    </div>
  </footer>
</template>

<script>
import footerDMenu from '@/components/FooterDMenu';
import SocialsList from '@/components/AppFooter/SocialsList';
import PaymentList from '@/components/AppFooter/PaymentList';
import Copyright from '@/components/AppFooter/Copyright';

export default {
  name: 'AppFooter',
  serverCacheKey: () => 'Footer',
  components: {
    footerDMenu,
    SocialsList,
    PaymentList,
    Copyright,
  },
};
</script>

<style lang="less">
.footer {
  background-color: @gray-back-2;
  padding: 30px 0;
  position: relative;

  &__container {
    .container-d;
  }

  &__info {
    border-top: 1px solid @gray-super-light;
    padding-top: @size-x5;
  }

  &__menus {
    padding-bottom: @size-x5;
  }

  &__box {
    display: grid;
    grid-template-columns: 1fr 255px 1fr;
  }

  &__one {
    &._copyright {
      margin-left: auto;
      max-width: 250px;
    }

    &._payment {
      margin-left: 38px;
    }
  }
}
</style>
