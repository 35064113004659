<template>
  <div class="location-d">
    <button
      ref="button"
      type="button"
      class="location-d__link"
      :class="mods"
      @click="showLocationPopup"
    >
      <icon-location16
        :width="16"
        :height="16"
        class="location-d__link-icon"
      />
      <span ref="text" class="location-d__link-text">
        {{ cityName }}
      </span>
    </button>
    <div v-show="isLocationPopup" class="location-d__popup">
      <button class="location-d__popup-close" @click="hideLocationPopup">
        <svg-icon
          name="16/Close_16"
          class="location-d__close-icon"
          width="16"
        />
      </button>
      <div class="location-d__popup-title">
        Ваш город —
      </div>
      <div class="location-d__popup-city">
        {{ cityName }}?
      </div>
      <div class="location-d__popup-buttons">
        <div class="location-d__popup-btn">
          <button-control
            size="small"
            class="_gray _location"
            @click="showCityModal"
          >
            Выбрать другой
          </button-control>
        </div>
        <div class="location-d__popup-btn">
          <button-control
            class="_location"
            size="small"
            @click="applyLocation"
          >
            Да, всё верно
          </button-control>
        </div>
      </div>
    </div>

    <city-modal
      is-world-delivery
      :city="{ name: cityName, location: cityLocation }"
      name="city-modal-header"
      @selected="modalSelectedHandler"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { ButtonControl } from '@book24/ui-components';

import IconLocation16 from '@/assets/sprite/svg/16/Location_16.svg';

import { useLocationContext } from '@/compositions/location/locationStorage';

export default defineComponent({
  name: 'ChooseCity',
  serverCacheKey: props => `ChooseCity::${props.locationCode}`,
  components: {
    ButtonControl,
    CityModal: () => import('@/components/CityModal'),
    IconLocation16,
  },
  props: {
    locationCode: {
      type: String,
      required: true,
    },
  },
  setup(props, { root: { $nextTick, $modal } }) {
    const { $cookies } = useContext();
    const locationComposition = useLocationContext();

    const button = ref(null);
    const text = ref(null);

    const localState = reactive({
      mods: '',
      locationRegion: null,
      locationType: null,
    });

    const getClassModsButton = () => {
      let mods = '';
      const btn = button.value;
      const textBtn = text.value;

      if (btn.offsetWidth < textBtn.offsetWidth) {
        mods = '_link-truncate';
      }
      localState.mods = mods;
    };

    /**
     * Инициализирует работу компонента
     */
    const init = async () => {
      await locationComposition.compareLocation();
      $nextTick(() => {
        getClassModsButton();
      });
    };

    /**
     * Отображает модальное окно подтверждение автовыбранного города
     */
    const showLocationPopup = () => {
      locationComposition.isLocationPopup.value = true;
    };

    /**
     * Скрывает модальное окно подтверждение автовыбранного города
     */
    const hideLocationPopup = () => {
      locationComposition.isLocationPopup.value = false;
    };

    /**
     * Открывает модальное окно выбора города
     */
    const showCityModal = async () => {
      hideLocationPopup();
      await $nextTick();
      $modal.show('city-modal-header');
    };

    /**
     * Применить выбранный город
     */
    const applyLocation = () => {
      $cookies.set('BITRIX_SM_location_accept', 'Y', locationComposition.optionsSetCookie);
      hideLocationPopup();
    };

    /**
     * Действие после выбора города из модалки
     * @params {Object} city - информация о выбранном городе
     */
    const modalSelectedHandler = ({ city }) => {
      locationComposition.setLocation(city);
      applyLocation();
      $modal.hide('city-modal-header');
      $nextTick(() => {
        getClassModsButton();
      });
    };

    onMounted(async () => {
      await init();
    });

    return {
      ...toRefs(localState),
      ...locationComposition,
      button,
      text,
      showLocationPopup,
      hideLocationPopup,
      showCityModal,
      applyLocation,
      modalSelectedHandler,
    };
  },
});
</script>

<style lang="less">
@import "./styles/index";
</style>
