<template>
  <div v-if="!isClosed" class="poster-container">
    <component
      :is="banner"
      :price="parameters.minimalDeliveryPrice"
      :delivery-price="parameters.deliveryPrice"
      :delivery-time="parameters.deliveryTime"
      :location-name="parameters.locationName"
      :is-mobile="true"
      @close="closeBanner"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import FivePost from '@/components/AppPoster/PosterWithHtml/FivePost';
import Location from '@/components/AppPoster/PosterWithHtml/Location';

export default defineComponent({
  name: 'PosterHtml',
  components: {
    FivePost,
    Location,
  },
  props: {
    group: { type: String, default: null },
    parameters: { type: Object, default: null },
  },
  setup(props) {
    const localState = reactive({
      isClosed: false,
    });

    const banner = computed({
      get: () => {
        if (props.group === 'FIVE_POST') {
          return 'five-post';
        }
        if (props.group === 'LOCATION') {
          return 'location';
        }
      },
      set: () => {},
    });

    const closeBanner = () => {
      localState.isClosed = true;
    };

    return {
      ...toRefs(localState),
      banner,
      closeBanner,
    };
  },
});
</script>

<style lang="less">
.app-banner-mobile {
  position: relative;
  background: url('@/static/_ssr/img/backgrounds/back-road.svg?inline') 0 0 repeat-x;
  height: 50px;

  &__close {
    .button-reset;

    border-radius: 100%;
    width: @size-x5;
    height: @size-x5;
    color: #142d3b;
    border: 1px solid currentColor;
    padding: 2px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: @main-size;
    top: @main-size;
  }

  &__close-icon {
    fill: currentColor;
  }

  &__content {
    .a-reset;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__body {
    padding: 0 @main-size 0 @size-x4;
  }

  &__title {
    margin: @main-size auto auto 0;
    font-size: 11px;
    font-weight: 800;
    line-height: 15px;
    text-align: left;
    color: #142d3b;
    max-width: 142px;
  }

  &__text {
    font-size: 9px;
    line-height: 13px;
    text-align: left;
    color: #142d3b;
    margin: 8px 30px auto auto;
    max-width: 104px;
  }

  &__icon._bike {
    width: 42px;
    min-width: 42px;
    height: auto;
    margin: 0 @main-size;
  }
}

._post-delivery {
  .app-banner-mobile__title {
    max-width: none;
  }

  .app-banner-mobile__text {
    max-width: none;
    margin: 0;
  }
}
</style>
