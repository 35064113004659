<template>
  <div class="fast-contacts">
    <a class="fast-contacts__only-phone" :href="`tel:${currentPhone}`">
      <svg-icon
        name="16/Call_phone_16"
        class="fast-contacts__icon"
        role="img"
        width="16"
        height="16"
      />
      {{ currentPhone }}
    </a>
    <div
      class="fast-contacts__dropdown"
      :class="{'_align': !callbackPhoneEnable }"
    >
      <div class="fast-contacts__work-hours">
        Часы работы:
      </div>
      <div class="fast-contacts__work-hours-exact">
        с 8 до 20 (МСК)
      </div>
      <client-only>
        <call-order-header
          v-if="callbackPhoneEnable"
          class="fast-contacts__callback"
        />
      </client-only>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CallOrderHeader from '@/components/_desktop/AppHeader/Callback/buttons/desktop/CallOrderHeader';

export default {
  name: 'FastContacts',
  serverCacheKey: props => `FastContacts::${props.currentPhone}`,
  components: { CallOrderHeader },
  props: {
    currentPhone: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      callbackPhoneEnable: state => state.siteSettings.callbackPhoneEnable,
    }),
  },
};
</script>

<style lang="less">
.fast-contacts {
  .small;

  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &__only-phone {
    .a-reset;

    height: 100%;
    color: @black;
    font-weight: bold;
    transition: @A color;
    position: relative;
    padding-left: @size-x6;
    padding-right: @main-size;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: @size-x2;
    fill: @gray;
    flex-shrink: 0;
  }

  &:hover &__icon {
    animation: 1.2s ease-in-out 0s normal none running shaking;
  }

  &__dropdown {
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: @A opacity;
    top: 100%;
    left: 0;
    width: 100%;
    background: @white;
    border: 1px solid @gray-super-light;
    box-sizing: border-box;
    box-shadow: 0 2px @main-size rgba(0, 0, 0, 0.25);
    border-radius: 0 0 @main-size @main-size;
    padding: @size-x3 @size-x4;

    &._align {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover &__dropdown {
    opacity: 1;
    visibility: visible;
    cursor: default;
  }

  &__work-hours {
    .small;

    font-weight: normal;
    color: @gray;
  }

  &__work-hours-exact {
    .small;

    font-weight: normal;
  }

  &__callback {
    margin-top: @size-x3;
  }
}
</style>
