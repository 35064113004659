<template>
  <div class="slogan">
    Официальный магазин издательской группы ЭКСМО-АСТ
  </div>
</template>

<script>
export default {
  name: 'AppSlogan',
  serverCacheKey: () => 'AppSlogan',
};
</script>

<style lang="less">
.slogan {
  .small2;

  color: @black;
  flex-shrink: 0;
}
</style>
