<template>
  <app-link href="/delivery/" class="delivery-link">
    <delivery-icon
      class="delivery-link__icon"
      role="img"
      width="16"
      height="16"
    />
    Доставка
  </app-link>
</template>

<script>
import deliveryIcon from '@/assets/sprite/svg/16/Delivery_16.svg';

export default {
  name: 'DeliveryLink',
  serverCacheKey: () => 'DeliveryLink',
  components: {
    deliveryIcon,
  },
};
</script>

<style lang="less">
@import "./styles/index";
</style>
