<template>
  <div class="catalog-sub-menu">
    <ul
      v-if="submenu.items != null"
      class="catalog-sub-menu__list"
    >
      <li
        v-for="item in submenu.items"
        :key="item.id"
        class="catalog-sub-menu__item"
      >
        <app-link
          class="catalog-sub-menu__link _title"
          :href="`/catalog/${item.code}-${item.id}/`"
        >
          {{ item.name }}
        </app-link>
        <ul v-if="item.items" class="catalog-sub-menu__child-list">
          <li
            v-for="subItem in item.items"
            :key="subItem.id"
            class="catalog-sub-menu__item"
          >
            <app-link
              class="catalog-sub-menu__link"
              :href="`/catalog/${subItem.code}-${subItem.id}/`"
            >
              {{ subItem.name }}
            </app-link>
          </li>
        </ul>
      </li>
    </ul>
    <menu-recommendations
      v-else-if="submenu.product"
      :products="submenu.product"
      :more-link="`/catalog/${submenu.code}-${submenu.id}/`"
    />
    <menu-logos />
  </div>
</template>

<script>
import MenuLogos from '@/components/_desktop/AppHeader/MainMenu/MenuSubPanel/MenuLogos';
import MenuRecommendations from '@/components/_desktop/AppHeader/MainMenu/MenuSubPanel/MenuRecommendations';

export default {
  components: {
    MenuLogos,
    MenuRecommendations,
  },
  props: {
    submenu: { required: true, type: Object },
  },
};
</script>

<style lang="less">
.catalog-sub-menu {
  display: flex;
  background-color: @white;
  width: 100%;

  &__list {
    .list-reset;

    column-fill: balance;
    column-count: 3;
    flex: 1 1 100%;
    column-width: 300px;
    column-gap: 24px;
    padding: 10px;

    @media (max-width: 1450px) {
      column-width: 200px;
      column-count: 2;
    }
  }

  &__child-list {
    .list-reset;
  }

  &__link {
    .a-reset;

    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: @gray-dark;
    display: block;

    &:hover,
    &._tap {
      color: @link-hover;
    }

    // title mod
    &._title {
      color: @black-native;

      &:hover,
      &._tap {
        color: @link-hover;
      }
    }

    // bold mod
    &._bold {
      font-weight: 600;
    }
  }

  &__item {
    padding: 6px 24px;
    display: inline-block;
    width: 100%;
  }

  a&__sub-title {
    .a-reset;

    &:hover,
    &._tap {
      color: @link;
    }
  }
}
</style>
