import { onBeforeMount, useContext, useRoute, watch } from '@nuxtjs/composition-api';

import { checkSpbRedirect } from '@/utils/checkSpbRedirect';

const ROUTE_NAME_RETURN_OF_GOODS = 'return-of-goods';
const ROUTE_NAME_RETURN_OF_GOODS_SPB = 'return-of-goods-spb';

const useRedirectReturnOfGoodsPage = () => {
  const { redirect, $cookies } = useContext();
  const route = useRoute();

  const baseRoute = '/return_of_goods/';
  const routeToSpb = '/return_of_goods_spb/';

  const switchRoutes = () => {
    const cityCode = $cookies.get('BITRIX_SM_location_code');
    const isRedirectToSpb = checkSpbRedirect(cityCode);

    if (isRedirectToSpb && route.value.name === ROUTE_NAME_RETURN_OF_GOODS) {
      redirect(301, { path: routeToSpb });
    } else if (!isRedirectToSpb && route.value.name === ROUTE_NAME_RETURN_OF_GOODS_SPB) {
      redirect(301, { path: baseRoute });
    }
  };

  watch(
    () => route,
    () => {
      switchRoutes();
    },
    { immediate: false },
  );

  onBeforeMount(async () => {
    switchRoutes();
  });

  return { switchRoutes };
};

export default useRedirectReturnOfGoodsPage;
