<template>
  <div class="menu-recommendations">
    <ul class="menu-recommendations__list">
      <li
        v-for="product in products"
        :key="product.id"
        class="menu-recommendations__item"
      >
        <recommendation-card :product="product" />
      </li>
    </ul>
    <div class="menu-recommendations__see-all">
      <app-link
        :href="moreLink || '/'"
        class="menu-recommendations__see-all-link"
      >
        Смотреть все
      </app-link>
    </div>
  </div>
</template>

<script>
import RecommendationCard from './RecommendationCard';

export default {
  components: {
    RecommendationCard,
  },
  props: {
    products: { type: Array, required: true },
    moreLink: { type: String, default: null },
  },
};
</script>

<style lang="less">
.menu-recommendations {
  padding: 10px;
  width: 100%;

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 0 24px;
  }

  &__list {
    column-fill: balance;
    column-count: 3;
    flex: 1 1 100%;
    column-width: 320px;
    column-gap: 24px;
    list-style: none;
    margin: 0;
    padding: 10px 24px;

    @media (max-width: 1550px) {
      column-width: 320px;
      column-count: 2;
    }

    @media (max-width: 1150px) {
      column-width: 320px;
      column-count: 1;
    }
  }

  &__item {
    break-inside: avoid;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__see-all {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid @gray-light;
  }

  &__see-all-link {
    .a-reset;

    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
    color: @link;
    transition: @A color;

    &:hover {
      color: @link-hover;
    }
  }
}
</style>
