<template>
  <div
    :data-b24-banner-name="name"
    :data-b24-banner-id="id"
    :data-b24-banner-link="url"
    :data-b24-banner-slot="topBannerSlot"
    :data-b24-banner-index="0"
    @click="handlerClickBanner({
      bannerName: name,
      bannerId: id,
      bannerLink: url,
      bannerSlot: topBannerSlot,
      bannerIndex: 0
    })"
  >
    <poster-html
      v-if="bannerGroup && bannerParameters"
      :group="bannerGroup"
      :parameters="bannerParameters"
    />
    <app-poster
      v-else
      add-ddl
      :campaign-id="id"
      :is-lazy-load="false"
      :not-use-fallback="true"
      :link-parent="url"
      :title="name"
      :alt="name"
      :item="bannerImage"
      class="app-poster"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
} from '@nuxtjs/composition-api';

import { useViewedBanners } from '@/compositions/analytics/useViewedBanners';
import { useAnalyticBanners } from '@/compositions/analytics/useAnalyticBanners';

import AppPoster from '@/components/AppPoster';
import PosterHtml from '@/components/AppPoster/PosterDelivery/index.vue';

export default defineComponent({
  name: 'ThePoster',
  components: { PosterHtml, AppPoster },
  props: {
    id: { type: [String, Number], default: null },
    name: { type: String, default: null },
    url: { type: String, default: null },
    imageOriginalSrc: { type: String, default: null },
    bannerGroup: { type: String, default: null },
    bannerParameters: { type: Object, default: null },
  },
  setup(props) {
    const analyticBanners = useAnalyticBanners();
    const { sendViewedBanners } = useViewedBanners();

    const bannerImage = computed(() => ({
      type: 'main',
      x1: props.imageOriginalSrc,
      x2: null,
      webpX1: null,
      webpX2: null,
    }));

    onMounted(() => {
      if (props.id) {
        sendViewedBanners();
      }
    });

    return {
      bannerImage,
      ...analyticBanners,
    };
  },
});
</script>

<style lang="less">
.app-poster {
  .container-d;

  min-height: 100px;
  width: 100%;
}
</style>
