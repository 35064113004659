import { MAIN_REGIONS, LENINGRAD_REGIONS, SPB_CITIES } from '@/utils/constants/globalConst';

export const checkSpbRedirect = (code) => {
  const isSpbCityCode = code === MAIN_REGIONS.spb.code;
  const isLeningradRegion = !!LENINGRAD_REGIONS.filter(item => item.code === code).length;
  const isSpbCity = !!SPB_CITIES.filter(item => item.code === code).length;
  const isRedirectToSpb = isSpbCityCode || isLeningradRegion || isSpbCity;

  return isRedirectToSpb;
};
