<template>
  <ul ref="menu" class="menu-general" :class="{_overflow: !isLoaded}">
    <li ref="menuBtn" class="menu-general__item _btn">
      <a
        href="/catalog/"
        class="menu-general__btn"
        :class="{_active: isOpenMenu}"
        @click.prevent="toggleMenu"
      >
        Каталог
        <nav-icon :is-open-menu="isOpenMenu" />
      </a>
    </li>
    <li
      v-for="item in menu"
      :key="item.title"
      ref="item"
      class="menu-general__item"
    >
      <app-link
        class="menu-general__link"
        :class="[item.mods, {_icon: item.icon}]"
        :href="item.link"
      >
        <component
          :is="item.icon.name"
          v-if="item.icon"
          class="menu-general__icon-link"
          :width="item.icon.width"
          :height="item.icon.height"
        />
        {{ item.title }}
      </app-link>
    </li>
    <li v-if="more_list.length" ref="moreBtn" class="menu-general__item">
      <a class="menu-general__link" @click.prevent="toggleMoreList">
        Ещё
        <svg-icon
          class="menu-general__icon"
          :class="{_active: isOpenMore}"
          name="16/Arrow_down_16"
          width="12"
          height="12"
        />
      </a>
      <ul
        v-if="isOpenMore"
        v-click-outside="closeMoreList"
        class="menu-general__list-more"
        @click="closeMoreList"
      >
        <li v-for="item in more_list" :key="item.title" class="menu-general__item">
          <app-link
            class="menu-general__link _more"
            :class="[item.mods, {_icon: item.icon}]"
            :href="item.link"
          >
            <component
              :is="item.icon.name"
              v-if="item.icon"
              class="menu-general__icon-link _more"
              :width="item.icon.width"
              :height="item.icon.height"
            />
            {{ item.title }}
          </app-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import throttle from 'lodash.throttle';

import NavIcon from '@/components/_desktop/AppHeader/MainMenu/MenuGeneral/NavIcon';

// icons
import Gift24Icon from '@/assets/sprite/svg/24/Gift_24.svg';

export default {
  components: {
    NavIcon,
    Gift24Icon,
  },
  props: {
    isOpenMenu: { type: Boolean, required: true },
  },
  data() {
    return {
      isLoaded: false,
      isOpenMore: false,
      menu: [
        { title: 'Акции', link: '/sales/' },
        { title: 'Новинки', link: '/novie-knigi/' },
        { title: 'Бестселлеры', link: '/knigi-bestsellery/' },
        { title: 'Книги с автографом', link: '/catalog/knigi-s-avtografom-4435/' },
        { title: 'Лучшие цены', link: '/best-price/' },
        { title: 'Рейтинги', link: '/rating/' },
        { title: 'Подборки', link: '/podborki/' },
        { title: 'Журнал', link: '/journal/' },
        {
          title: 'Подарки',
          link: '/podarki/',
          icon: { name: 'Gift24Icon', width: 32, height: 32 },
          mods: '_gift',
        },
      ],
      more_list: [],
    };
  },
  mounted() {
    this.setWidth();
    this.check();
    this.setLoaded();
    window.addEventListener('resize', throttle(this.check, 500));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.check);
  },
  methods: {
    closeMoreList() {
      this.isOpenMore = false;
    },
    setLoaded() {
      this.isLoaded = true;
    },
    toggleMenu() {
      this.$emit('toggleMenu', !this.isOpenMenu);
    },
    toggleMoreList() {
      this.isOpenMore = !this.isOpenMore;
    },
    popElement(array, element) {
      const idx = array.findIndex(item => item.title === element.title);
      const found = array.splice(idx, 1);
      if (found) {
        return found[0];
      }
    },
    setWidth() {
      const items = this.$refs.item;
      const tmp = [];

      items.forEach((element) => {
        const transfer = this.menu.find(item => item.title === element.textContent.trim());
        tmp.push({ offsetWidth: element.offsetWidth, ...transfer });
      });
      this.menu = tmp;
    },
    check() {
      this.$nextTick(async () => {
        let items = [...this.menu];
        this.menu = [];

        if (this.more_list.length) {
          items = [...items, ...this.more_list];
          this.more_list = [];
        }

        await this.$nextTick();

        const { menu, menuBtn } = this.$refs;

        if (!menu?.offsetWidth) return;

        const maxWidth = menu?.offsetWidth - menuBtn?.offsetWidth - 100;

        let width = 0;
        const tempMoreList = [];
        const tempMenu = [...items];
        items.forEach((item) => {
          if (width + item.offsetWidth > maxWidth) {
            const transfer = this.popElement(tempMenu, item);
            tempMoreList.push(transfer);
          }
          width += item.offsetWidth;
          this.more_list = tempMoreList;
          this.menu = tempMenu;
        });
      });
    },
  },
};
</script>

<style lang="less">
.menu-general {
  display: flex;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;

  &._overflow {
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: @size-x6;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      z-index: 1;
    }
  }

  &__btn {
    .a-reset;
    .p;

    display: flex;
    border: none;
    background: none;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 600;
    padding: 12px 20px;
    color: @black;
    transition: @A color;

    &._active {
      color: @link-hover;
    }
  }

  &__item {
    .p;

    position: relative;
    font-weight: 600;
    color: @black;
    white-space: nowrap;
    display: flex;
    align-items: center;
    transition: @A color;

    &._btn {
      padding-left: 0;
      border-right: 1px solid @gray-super-light;
    }
  }

  &__icon {
    margin-left: 8px;
    transition: @A transform;

    &._active {
      transform: rotate(180deg);
    }
  }

  &__link {
    display: flex;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    padding: 12px 20px;
    align-items: center;

    &._icon {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2px;
      align-items: center;
    }

    &._more {
      width: 100%;
    }

    &._icon._more {
      padding: 8px 20px 8px 18px;
    }

    &._school {
      color: @green-dark;
    }

    &._happy-new-year {
      .menu-general__icon-link {
        position: relative;
        top: -4px;

        &._more {
          top: 0;
        }
      }
    }
  }

  &__list-more {
    position: absolute;
    right: 1px;
    top: 100%;
    border-radius: 0 0 @main-size @main-size;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.25);
    background-color: @white;
    border: solid 1px #d8dcde;
    padding: @size-x2 0;
    opacity: 1;
    z-index: 2;
    transition: @A visibility, @A opacity;
    list-style: none;
    margin: 0;
  }

  &__list-more &__item {
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: @gray-back;
      color: @link-hover;
    }
  }

  &__icon-link {
    margin-right: @size-x2;
  }

  &__link._gift &__icon-link {
    fill: @pink-error;
  }
}
</style>
