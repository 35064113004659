<template>
  <div
    v-if="isShow"
    :class="{ _mobile: isMobile }"
    class="app-newbies-notification"
    @click="clickHandler"
  >
    <div class="app-newbies-notification__container">
      <div class="app-newbies-notification__inner">
        <p class="app-newbies-notification__text">
          Войдите или зарегистрируйтесь, чтобы получить скидку 30% на первый заказ
        </p>
        <button-control
          size="small"
          class="app-newbies-notification__more"
          @click.stop="clickMoreHandler"
        >
          Подробнее
        </button-control>
      </div>
    </div>

    <button
      class="app-newbies-notification__close"
      type="button"
      aria-label="Закрыть"
      @click.stop="closeHandler"
    >
      <close-icon class="app-newbies-notification__close-icon" />
    </button>
  </div>
</template>

<script>
import { defineComponent, ref, useStore, useRouter } from '@nuxtjs/composition-api';
import { ButtonControl } from '@book24/ui-components';

import { checkMobile } from '@/utils/deviceCheck';

import useAuthorization from '@/compositions/authorization/useAuthorization';

import CloseIcon from '@/assets/sprite/svg/24/Close_24.svg';

export default defineComponent({
  name: 'AppNewbiesNotification',
  components: {
    CloseIcon,
    ButtonControl,
  },
  setup() {
    const isMobile = checkMobile();
    const authorization = useAuthorization();
    const { commit } = useStore();

    const isShow = ref(true);

    const closeHandler = () => {
      isShow.value = false;
      commit('setHideNewbiesNotification', true);
    };

    const clickHandler = () => {
      authorization.openAuthorizationModal('Newbies Banner');
      closeHandler();
    };

    const router = useRouter();
    const clickMoreHandler = () => {
      router.push('/sales/6746684/');
    };

    return {
      isShow,
      isMobile,
      clickHandler,
      closeHandler,
      clickMoreHandler,
    };
  },
});
</script>

<style lang="less">
.app-newbies-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: @size-x2;
  padding-bottom: @size-x2;
  background: @yellow-light;
  height: 48px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 100501;

  &._mobile {
    height: 108px;
    padding: @size-x2 @size-x4;
  }

  &:hover {
    background: @yellow;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1000px;
    position: relative;
  }

  &__text {
    .small;

    font-weight: 600;
    padding-right: 16px;
    margin: 0;
  }

  &__more {
    width: 104px;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close {
    .button-reset;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 16px;
    background: @orange;
    border-radius: 100%;
    transition: background-color 0.3s;

    &:hover {
      background: @pink-error;
    }
  }

  &__close-icon {
    width: 20px;
    height: 20px;
    fill: @white;
  }

  &._mobile &__container {
    min-width: unset;
  }

  &._mobile &__text {
    .small2;
  }

  &._mobile &__close {
    width: 20px;
    height: 20px;
  }

  &._mobile &__close-icon {
    width: 14px;
    height: 14px;
  }

  &._mobile &__inner {
    flex-direction: column;
    justify-content: stretch;
    gap: 12px;
  }

  &._mobile &__more {
    width: 100%;
  }
}
</style>
