<template>
  <app-link
    :href="url"
    class="search-product"
    @click.native="clickHandler"
  >
    <div class="search-product__image-block">
      <img :src="img" alt="" class="search-product__image-pic" />
    </div>
    <div class="search-product__container">
      <span
        v-sanitize="name"
        class="search-product__title"
      ></span>
      <div class="search-product__price">
        <div class="search-product__price-current">
          <app-price :price="price" />
        </div>
        <div
          v-if="price < priceBase"
          class="search-product__price-old"
        >
          <div class="search-product__price-old-value">
            <app-price :price="priceBase" />
          </div>
          <div
            v-if="percentDiscount !== null"
            class="search-product__price-discount"
          >
            <div class="search-product__badge">
              -{{ percentDiscount }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-link>
</template>

<script>
import AppPrice from '@/components/AppPrice';

export default {
  name: 'SearchProduct',
  components: {
    AppPrice,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '/_ssr/img/not-image/Not_image_book.png',
    },
    name: {
      type: String,
      required: true,
    },
    priceBase: {
      type: Number,
      default: 0,
    },
    price: {
      type: Number,
      default: 0,
    },
    percentDiscount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    // показывать ли блок цены
    showPrices() {
      return this.priceBase && this.price;
    },
  },
  methods: {
    clickHandler() {
      this.$emit('clickHandler');
    },
  },
};
</script>

<style lang="less">
@import "./styles/index";
</style>
