<template>
  <ul class="menu-logos">
    <li
      v-for="(logo, index) in logos"
      :key="index"
      class="menu-logos__item"
    >
      <app-link class="menu-logos__link" :href="logo.url">
        <img
          :src="logo.icon"
          :alt="logo.alt"
          class="menu-logos__img"
          width="80"
          :height="logo.height || 36"
          :style="{ maxHeight: `${logo.height}px` }"
        />
      </app-link>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      logos: [
        {
          icon: '/_ssr/img/logo-publisher/001_eksmo.svg',
          url: '/brand/eksmo/',
          alt: 'Издательство Эксмо',
        },
        {
          icon: '/_ssr/img/logo-publisher/002_ast.svg',
          url: '/brand/ast/',
          alt: 'Издательство АСТ',
        },
        {
          icon: '/_ssr/img/logo-publisher/010_mif.svg',
          url: '/brand/mif/',
          alt: 'Издательство "Манн, Иванов и Фербер"',
        },
        {
          icon: '/_ssr/img/logo-publisher/008_bombora_v2.svg',
          url: '/brand/bombora/',
          alt: 'БОМБОРА',
        },
        {
          icon: '/_ssr/img/logo-publisher/016_inspiria-new.png',
          url: '/brand/inspiria/',
          alt: 'Inspiria',
        },
        {
          icon: '/_ssr/img/logo-publisher/006_corpus.svg',
          url: '/brand/corpus/',
          alt: 'Corpus',
        },
        {
          icon: '/_ssr/img/logo-publisher/009_komilfo.png',
          url: '/brand/komilfo/',
          alt: 'Комильфо',
        },
        {
          icon: '/_ssr/img/logo-publisher/004_fanzon-new.svg',
          url: '/brand/fanzon/',
          alt: 'Fanzon',
          height: 60,
        },
        {
          icon: '/_ssr/img/logo-publisher/021_cherno-belo.svg',
          url: '/brand/chernym-belo/',
          alt: 'Черным Бело',
          height: 60,
        },
      ],
    };
  },
};
</script>

<style lang="less">
.menu-logos {
  .list-reset;

  margin: 0 0 0 auto;
  padding: @size-x4 0 @size-x4 @size-x6;

  &__item {
    display: flex;
    justify-content: center;
    margin-bottom: @size-x6;
  }

  &__img {
    max-width: 80px;
    max-height: 36px;
    width: auto;
    height: auto;
  }
}
</style>
