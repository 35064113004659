<template>
  <div class="app-search-field">
    <input-control
      ref="input"
      :value="value"
      class="app-search-field__input _search"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :name="name"
      :loading="loading"
      :clearable="!!value"
      @input="changeValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @clear="clearHandler"
    >
      <template #prepend>
        <search-icon
          class="b24-input-control__control-icon"
          width="24"
          height="24"
        />
      </template>
    </input-control>
    <button-control
      native-type="submit"
      class="_search"
      @click.prevent="clickHandler"
    >
      Найти
    </button-control>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { InputControl, ButtonControl } from '@book24/ui-components';
import SearchIcon from '@/assets/sprite/svg/24/Search_24.svg';

export default defineComponent({
  name: 'AppSearchField',
  components: {
    InputControl,
    SearchIcon,
    ButtonControl,
  },
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: 'Поиск книг, авторов' },
    visibleSearchIcon: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    autocomplete: { type: String, default: 'off' },
    name: { type: String, default: 'q' },
  },
  setup(props, { emit, refs }) {
    const changeValue = (value) => {
      emit('input', value);
    };
    const handleFocus = (event) => {
      emit('focus', event);
    };

    const handleBlur = (event) => {
      emit('blur', event);
    };

    const clickHandler = () => {
      emit('find', props.value);
    };

    const resetFocus = () => {
      refs.input.blur();
    };

    const clearHandler = () => {
      emit('clear');
    };
    return {
      changeValue,
      handleFocus,
      handleBlur,
      clickHandler,
      resetFocus,
      clearHandler,
    };
  },
});
</script>

<style lang="less">
.app-search-field {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid @gray-light;
  border-radius: 4px;
  transition: @A border-color;

  &._focus {
    border-color: @link;
  }

  &__input {
    width: 100%;
  }
}
</style>
