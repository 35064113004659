<template>
  <content-placeholders class="app-poster">
    <content-placeholders-img style="width: 100%; height: 100px;" />
  </content-placeholders>
</template>

<script>
export default {
  name: 'ThePosterSkeleton',
};
</script>
