<template>
  <app-link class="menu-recommendation-card" :href="`/product/${product.code}-${product.id}/`">
    <div class="menu-recommendation-card__image-container">
      <card-adult v-if="checkIsAdult && product.isAdult" />
      <img
        class="menu-recommendation-card__image"
        :src="getImage"
        :alt="product.name"
        loading="lazy"
      />
    </div>
    <div class="menu-recommendation-card__content">
      <div class="menu-recommendation-card__title">
        {{ product.name }}
      </div>
      <div class="menu-recommendation-card__author">
        {{ product.authorName }}
      </div>
    </div>
  </app-link>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';

import useAdult from '@/compositions/adult/useAdult';

export default {
  props: {
    product: { type: Object, required: true },
  },
  setup(props) {
    const { checkIsAdult } = useAdult();

    const getImage = computed(() => {
      if (props.product?.picture?.url) return props.product.picture.url;
      return '/_ssr/img/pic-default.png';
    });

    return {
      getImage,
      checkIsAdult,
    };
  },
};
</script>

<style lang="less">
.menu-recommendation-card {
  display: flex;
  height: 72px;
  width: 320px;
  text-decoration: none;
  break-inside: avoid;

  &__image-container {
    position: relative;
    width: 40px;
    max-height: 72px;
    flex-shrink: 0;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__content {
    padding-left: 16px;
    max-width: calc(100% - 56px);
  }

  &__title {
    max-height: 48px;
    font-size: 14px;
    line-height: 24px;
    color: @black;
    transition: @A color;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__author {
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    color: @gray-dark;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover &__title {
    color: @link-hover;
  }
}
</style>
