<template>
  <div v-if="catalog.length" class="menu-first-panel">
    <h2 class="menu-first-panel__title">
      Разделы
    </h2>
    <ul class="menu-first-panel__list">
      <li
        v-for="item in catalog"
        :key="item.id"
        class="menu-first-panel__item"
        :class="{ _active: activeMenu === item.id }"
        @mouseenter="openMenu(item)"
        @mouseleave="closeMenu"
      >
        <app-link
          :href="`/catalog/${item.code}-${item.id}/`"
          class="menu-first-panel__link"
        >
          <span class="menu-first-panel__text">
            {{ item.name }}
          </span>
        </app-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  defineComponent,
  useStore,
  computed,
  ref,
  watch,
} from '@nuxtjs/composition-api';

export default defineComponent({
  setup(props, { emit }) {
    let timer = null;
    const { state } = useStore();
    const activeMenu = ref(0);
    const isSetActiveMenu = ref(false);
    const catalog = computed({
      get: () => state.main.catalogMenu,
      set: () => {},
    });

    const changeActiveMenu = (value) => {
      activeMenu.value = value.id;
      emit('activeMenu', value);
    };

    const closeMenu = () => {
      clearTimeout(timer);
    };

    const openMenu = (value) => {
      closeMenu();
      timer = setTimeout(changeActiveMenu, 100, value);
    };

    const setActiveMenu = () => {
      if (!isSetActiveMenu.value && Array.isArray(catalog.value) && catalog.value.length > 0) {
        changeActiveMenu(catalog.value[0]);
        isSetActiveMenu.value = true;
      }
    };

    setActiveMenu();

    watch(
      () => catalog.value,
      () => {
        setActiveMenu();
      },
    );

    return {
      catalog,
      activeMenu,
      openMenu,
      closeMenu,
    };
  },
});
</script>

<style lang="less">
.menu-first-panel {
  width: 270px;
  flex-shrink: 0;
  padding-top: @size-x2;
  border-right: 1px solid @gray-light;

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }

  &__list {
    .list-reset;

    padding: @main-size 0 @size-x2;
  }

  &__item {
    position: relative;

    &._active::after {
      left: 100%;
      top: 0;
      content: url(~'/_ssr/img/backgrounds/arrow_border.svg?inline');
      position: absolute;
      pointer-events: none;
      height: 40px;
    }
  }

  &__link {
    .a-reset;
    .small;

    position: relative;
    z-index: 2;
    text-align: left;
    display: flex;
    align-items: center;
    padding: @size-x2 @size-x6 @size-x2 0;
    transition: @A background-color, @A color;

    &:hover {
      color: @link-hover;
    }
  }

  &__text {
    flex: auto;
  }

  &__icons {
    height: 24px;
    margin-right: @size-x2;
  }

  &__icon {
    fill: rgba(0, 0, 0, 0.25);
    transition: @A fill;
  }
}
</style>
