import { useStore, useRoute, computed } from '@nuxtjs/composition-api';

import useHttpClient from '@/compositions/useHttpClient';

export default () => {
  const httpClient = useHttpClient();
  const route = useRoute();
  const store = useStore();

  const isAuthorized = computed({
    get: () => store.state.isAuthorized,
    set: () => {},
  });

  /**
   * Получает партнёрские ссылки юзера
   * @return {Promise<void>}
   */
  const getPartnerLinks = async () => {
    const url = `https://book24.ru${route.value.fullPath}`;
    const source = 'site';

    const { link, shortLink } = await httpClient.partner.getPartnerLinks({
      url,
      source,
    });

    store.commit('setPartnerLinks', {
      link,
      shortLink,
    });
  };

  /**
   * Проверяет, является ли юзер партнёром
   * @return {Promise<void>}
   */
  const checkCurrentUserPartner = async () => {
    if (!isAuthorized.value) { return; }

    await httpClient.partner.checkCurrentUserPartner()
      .then(() => {
        store.commit('setIsUserPartner', true);
        getPartnerLinks();
      })
      .catch(() => {
        store.commit('setIsUserPartner', false);
      });
  };

  return {
    checkCurrentUserPartner,
  };
};
