<template>
  <ul ref="menu" class="second-menu" :class="{_overflow: !isLoaded}">
    <li v-if="moreList.length" ref="moreBtn" class="second-menu__item _more">
      <button
        type="button"
        class="second-menu__link _button"
        :class="{_active: isOpenMore}"
        @click.prevent="toggleMoreList"
      >
        <span class="second-menu__link-text">
          Ещё
        </span>
        <svg-icon
          name="16/Arrow_down_16"
          class="second-menu__link-icon"
          role="img"
          width="10"
          height="10"
        />
      </button>
      <ul
        v-if="isOpenMore"
        v-click-outside="closeMoreList"
        class="second-menu__list-more"
        @click="closeMoreList"
      >
        <li v-for="item in moreList" :key="item.title" class="second-menu__item">
          <app-link class="second-menu__link" :href="item.link" v-bind="bindLink(item)">
            {{ item.title }}
          </app-link>
        </li>
      </ul>
    </li>
    <li
      v-for="item in menu"
      :key="item.title"
      ref="item"
      class="second-menu__item"
    >
      <app-link
        class="second-menu__link"
        :href="item.link"
        v-bind="bindLink(item)"
      >
        {{ item.title }}
      </app-link>
    </li>
  </ul>
</template>

<script>
import throttle from 'lodash.throttle';
import data from './data.json';

export default {
  name: 'SecondMenu',
  serverCacheKey: () => 'SecondMenu',
  data() {
    return {
      isLoaded: false,
      isOpenMore: false,
      menu: data,
      moreList: [],
    };
  },
  mounted() {
    this.setWidth();
    this.check();
    this.setLoaded();
    window.addEventListener('resize', throttle(this.check, 500));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.check);
  },
  methods: {
    bindLink(item) {
      const attrs = {};
      if (item?.isBlank) {
        attrs.blank = 'blank';
      }
      return attrs;
    },
    closeMoreList() {
      this.isOpenMore = false;
    },
    setLoaded() {
      this.isLoaded = true;
    },
    toggleMenu() {
      this.$emit('toggleMenu', !this.isOpenMenu);
    },
    toggleMoreList() {
      this.isOpenMore = !this.isOpenMore;
    },
    popElement(array, element) {
      const idx = array.findIndex(item => item.title === element.title);
      const found = array.splice(idx, 1);
      if (found) {
        return found[0];
      }
    },
    setWidth() {
      const items = this.$refs.item;
      const tmp = [];

      items.forEach((element) => {
        const transfer = this.menu.find(item => item.title === element.textContent.trim());
        tmp.push({ offsetWidth: element.offsetWidth, ...transfer });
      });
      this.menu = tmp;
    },
    check() {
      this.$nextTick(async () => {
        let items = [...this.menu];
        this.menu = [];

        if (this.moreList.length) {
          items = [...items, ...this.moreList];
          this.moreList = [];
        }

        await this.$nextTick();

        const { menu } = this.$refs;

        if (!menu?.offsetWidth) return;

        const maxWidth = menu?.offsetWidth - 60;

        let width = 0;
        const tempMoreList = [];
        const tempMenu = [...items];
        items.forEach((item) => {
          if (width + item.offsetWidth > maxWidth) {
            const transfer = this.popElement(tempMenu, item);
            tempMoreList.push(transfer);
          }
          width += item.offsetWidth;
          this.moreList = tempMoreList;
          this.menu = tempMenu;
        });
      });
    },
  },
};
</script>

<style lang="less">
.second-menu {
  .list-reset;

  position: relative;
  display: flex;

  &._overflow {
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: @size-x6;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      z-index: 1;
    }
  }

  &__item {
    position: relative;

    &._more {
      order: 1;
    }
  }

  &__link {
    .a-reset;
    .button-reset;
    .small2;

    color: @link;
    letter-spacing: normal;
    padding: @main-size 10px;
    display: flex;
    align-items: center;
    transition: @A color;
    white-space: nowrap;

    &._button {
      cursor: pointer;
    }

    &:hover,
    &:active,
    &._tap {
      color: @black;
    }
  }

  &__link-icon {
    fill: @link;
    transition: @A fill, @A transform;
    margin-left: 6px;
    margin-top: 3px;
  }

  &__link:hover &__link-icon,
  &__link:active &__link-icon,
  &__link._tap &__link-icon {
    fill: @black;
  }

  &__list-more {
    .list-reset;

    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: @white;
    border: solid 1px #d8dcde;
    padding: 6px 0;
    opacity: 1;
    visibility: visible;
    z-index: 2;
    transition: @A visibility, @A opacity;
  }

  &__list-more &__link {
    white-space: nowrap;
    padding: 6px 15px;
    color: @black;
    transition: @A color, @A background-color;

    &:hover {
      background-color: #f2f3f7;
      color: @link;
    }
  }

  // active link mod
  &__link._active {
    color: @black;
  }

  &__link._active &__link-icon {
    fill: @black;
    transform: rotate(180deg);
  }
}
</style>
