<template>
  <span class="nav-icon" :class="{_active: isOpenMenu}">
    <span class="nav-icon__content"></span>
    <span class="nav-icon__content"></span>
    <span class="nav-icon__content"></span>
  </span>
</template>

<script>
export default {
  components: {
  },
  props: {
    isOpenMenu: { type: Boolean, required: true },
  },
};
</script>

<style lang="less">
.nav-icon {
  width: 24px;
  height: 15px;
  position: relative;
  transform: rotate(0);
  transition: 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-left: 16px;
  color: @gray;

  &__content {
    height: 2px;
    width: 100%;
    background-color: currentColor;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0);
    transition: 0.3s ease-in-out;
    margin: 0;

    &:nth-child(1) {
      transform-origin: left center;
    }

    &:nth-child(2) {
      transform-origin: left center;
      margin: 4px 0;
    }

    &:nth-child(3) {
      transform-origin: left center;
    }
  }

  &._active &__content {
    &:nth-child(1) {
      transform: rotate(45deg) translate(0, -3px);
    }

    &:nth-child(2) {
      transform: scale(0);
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(0, 3px);
    }
  }
}
</style>
