<template>
  <nav class="footer-d__menu">
    <div class="footer-d-menu">
      <div class="footer-d-menu__left">
        <div
          v-for="item in firstColumnList"
          :key="item.link"
          class="footer-d-menu__item"
          :class="item.mods"
        >
          <app-link
            class="footer-d-menu__link"
            :href="item.link === '/return_of_goods/' ? changeLinkReturnOfGoods() : item.link "
          >
            <svg-icon
              v-if="item.icon"
              :name="item.icon.name"
              class="footer-d-menu__link-icon"
              :width="item.icon.width"
              :height="item.icon.height"
            />
            {{ item.name }}
          </app-link>
        </div>
      </div>
      <div class="footer-d-menu__center">
        <div class="footer-d-menu__center-inner">
          <div
            v-for="item in secondColumnList"
            :key="item.link"
            class="footer-d-menu__item"
            :class="item.mods"
          >
            <app-link
              class="footer-d-menu__link"
              :href="item.link === '/return_of_goods/' ? changeLinkReturnOfGoods() : item.link "
            >
              <svg-icon
                v-if="item.icon"
                :name="item.icon.name"
                class="footer-d-menu__link-icon"
                :width="item.icon.width"
                :height="item.icon.height"
              />
              {{ item.name }}
            </app-link>
          </div>
        </div>
      </div>
      <div class="footer-d-menu__right">
        <div class="footer-d-menu__right-inner">
          <div
            v-for="item in thirdColumnList"
            :key="item.link"
            class="footer-d-menu__item"
            :class="item.mods"
          >
            <app-link
              class="footer-d-menu__link"
              :href="item.link === '/return_of_goods/' ? changeLinkReturnOfGoods() : item.link "
            >
              <svg-icon
                v-if="item.icon"
                :name="item.icon.name"
                class="footer-d-menu__link-icon"
                :width="item.icon.width"
                :height="item.icon.height"
              />
              {{ item.name }}
            </app-link>
          </div>
          <div class="footer-d-menu__item">
            <a class="footer-d-menu__link" :href="`tel:${currentPhone}`">{{ currentPhone }}</a>
          </div>
          <div class="footer-d-menu__item">
            <a class="footer-d-menu__link" :href="`mailto:${imarketEmail}`">{{ imarketEmail }}</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import { checkSpbRedirect } from '@/utils/checkSpbRedirect';
import data from './data.json';

export default {
  name: 'FooterDMenu',
  data() {
    return {
      menuList: data,
    };
  },
  computed: {
    ...mapState({
      currentPhone: state => state.main.currentPhone,
      imarketEmail: state => state.main.imarketEmail,
    }),
    firstColumnList() {
      return this.menuList.slice(0, 5);
    },
    secondColumnList() {
      return this.menuList.slice(5, 10);
    },
    thirdColumnList() {
      return this.menuList.slice(10, this.menuList.length);
    },
  },
  methods: {
    changeLinkReturnOfGoods() {
      const { $cookies } = this.$root.$options;
      const currentLocationCode = $cookies.get('BITRIX_SM_location_code');
      const isRedirectToSpb = checkSpbRedirect(currentLocationCode);

      if (isRedirectToSpb) {
        return '/return_of_goods_spb/';
      }
      return '/return_of_goods/';
    },
  },
};
</script>

<style lang="less">
.footer-d-menu {
  display: grid;
  grid-template-columns: 1fr 255px 1fr;

  &__center {
    display: flex;
    justify-content: center;
  }

  &__right-inner {
    margin-left: auto;
    max-width: 250px;
  }

  &__item {
    line-height: normal;
    margin: 0 @size-x3 @size-x3 0;
  }

  &__link {
    .a-reset;
    .small(18px);

    text-align: left;
    color: @black;

    &:hover,
    &._tap {
      color: @link-hover;
    }

    &:active {
      color: @link-visited;
    }
  }

  // blog item mod
  &__item._blog &__link-icon {
    margin-right: 8px;
  }

  &__item._blog &__link {
    color: @pink-error;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}
</style>
