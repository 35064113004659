<template>
  <div class="search-suggestions">
    <div v-if="title" class="search-suggestions__title">
      {{ title }}
      <button
        v-if="isHistory"
        class="search-suggestions__button"
        @click="clearHistoryHandler"
      >
        Очистить
      </button>
    </div>
    <ul class="search-suggestions__list">
      <li
        v-for="(item, key) in results"
        :key="item.id"
        class="search-suggestions__list-item"
        :class="{
          _hovered: active === item.id,
          _regular: !isCategory(item) && !isProduct(item),
          _category: isCategory(item),
          _product: isProduct(item),
        }"
      >
        <app-link
          v-if="!isProduct(item)"
          v-sanitize="getSearchCaption(item.title, query)"
          class="search-suggestions__link"
          :class="{
            _category: isCategory(item),
          }"
          :href="item.link"
          @click.native="linkClickHandler(item.title, item.link)"
        />
        <search-product
          v-else
          :id="item.meta.id"
          :url="item.link"
          :name="getSearchCaption(item.title, query)"
          :price-base="item.meta.originalPrice"
          :price="item.meta.price"
          :percent-discount="item.meta.discount"
          :img="item.meta.image"
          :class="{_hovered: active === item.id}"
          @clickHandler="linkClickHandler(item.title, item.link)"
        />
        <div
          v-if="isCategory(item)"
          class="search-suggestions__bread-crumbs"
        >
          <app-link
            v-for="breadCrumb in item.meta.breadCrumb"
            :key="breadCrumb.url"
            v-sanitize="getSearchCaption(breadCrumb.name, query)"
            class="search-suggestions__link _category-bread-crumb"
            :href="breadCrumb.url"
            @click.native="linkClickHandler(breadCrumb.name, breadCrumb.url)"
          />
        </div>
        <button
          v-if="isHistory"
          type="button"
          class="search-suggestions__list-clear"
          @click.prevent="clearHistoryHandler(key)"
        >
          <svg-icon
            name="24/Close_24"
            width="24"
            height="24"
            class="search-suggestions__list-clear-icon"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import SearchProduct from '@/components/_desktop/AppHeader/TheSearch/SearchProduct';

const wrapQuery = query => `<b>${query}</b>`;
const escape = query => query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export default {
  components: {
    SearchProduct,
  },
  props: {
    title: { type: String, default: '' },
    query: { type: String, default: '' },
    results: { type: Array, required: true },
    isHistory: { type: Boolean, default: false },
    active: { type: [String, Number], default: -1 },
  },
  methods: {
    getSearchCaption(caption, query) {
      if (query) {
        const re = new RegExp(escape(query), 'ig');
        return caption.replace(re, wrapQuery);
      }

      return caption;
    },
    linkClickHandler(title, url) {
      this.$emit('clickHandler', {
        title,
        url,
      });
    },
    isCategory(item) {
      return item.meta?.breadCrumb;
    },
    isProduct(item) {
      return item.meta?.id;
    },
    clearHistoryHandler(key = null) {
      this.$emit('clearHistory', key);
    },
  },
};
</script>

<style lang="less">
@import "./styles/index";
</style>
